import React from 'react'

import List from '../../components/List/List.jsx'

import { getRequirementSet, defaultNutrientSettings } from '../../utils.js'

const defaultSettings = defaultNutrientSettings()
const NutrientSettings = ({ props, dispatch, warningCollapsed, setWarningCollapsed }) => {
	const requirementSet = getRequirementSet(props.settings)[1]
	const nutrients = Object.keys(requirementSet)
	const excessIgnoredNutrients = nutrients.filter(nutrient => (
		props.nutrientSettings
		&& props.nutrientSettings[nutrient]
		&& props.nutrientSettings[nutrient].ignoreExcess
	))
	const defaultKeys = Object.keys(defaultSettings)
	const currentKeys = Object.keys(props.nutrientSettings)
	const matchesDefaults = (
		defaultKeys.length === currentKeys.length
		&& defaultKeys.every(nutrient => {
			const defaultNutrientSettings = defaultSettings[nutrient] || {}
			const defaultNutrientWeight = defaultNutrientSettings.weight || 1
			const defaultNutrientIgnoreExcess = defaultNutrientSettings.ignoreExcess || false
			const nutrientSettings = props.nutrientSettings[nutrient] || {}
			const nutrientWeight = nutrientSettings.weight || 1
			const nutrientIgnoreExcess = nutrientSettings.ignoreExcess || false
			return (
				defaultNutrientWeight === nutrientWeight
				&& defaultNutrientIgnoreExcess === nutrientIgnoreExcess
			)
		})
	)

	return (
		<div className="NutrientSettings flex_grow_1">
			<h3 className="flex space_between">
				Nutrients
				&nbsp;
				{matchesDefaults ? null : <span 
					className="interactive" 
					title="Restore defaults"
					onClick={e => {
						const nutrientSettings = defaultNutrientSettings()
						const actions = []
						Object.keys(nutrientSettings).forEach(nutrient => {
							const { weight, ignoreExcess } = nutrientSettings[nutrient]
							localStorage.setItem(`nutrient_${nutrient}`, `${weight},${ignoreExcess}`)
							actions.push({
								type: 'SET_IGNORE_NUTRIENT_EXCESS',
								ignoreExcess, nutrient
							})
							actions.push({
								type: 'SET_NUTRIENT_WEIGHT',
								weight: weight || 1, 
								nutrient
							})
						})
						dispatch(actions)
					}}
				>&#10226; Restore defaults</span>}
			</h3>
			<div 
				className="warning_text interactive" 
				onClick={() => setWarningCollapsed(!warningCollapsed)}
			>
				<div>&#9888; Warning</div>
				{
					warningCollapsed
					? null
					: <>
						<p>These values are crucial to the underlying fitness calculation.</p>
						<p>Manipulating them can greatly influence the final mix and should be done with caution.</p>
						<p>Useful if:</p>
						<ul>
							<li>You're getting ridiculous results with your available ingredients and want to tweak the underlying algorithm to get a practical mix.</li>
							<li>You disagree with the way nutrients are factored into fitness on a scientific basis.</li>
						</ul>
					</>
				}
			</div>
			<List 
				rows={[
					{
						flex: true,
						content: <>
							<span className="flex_grow_1">Nutrient</span>
							<span className="flex_grow_1">Weight</span>
							<span className="flex_grow_1">
								<div className="center_text">Ignore excess</div>
								<div className="flex justify_center">
									<input 
										title="Ignore all"
										type="checkbox"
										checked={nutrients.length === excessIgnoredNutrients.length}
										onClick={e => {
											nutrients.forEach(nutrient => {
												localStorage.setItem(
													`nutrient_${nutrient}`, 
													`${props.nutrientSettings[nutrient].weight || 1},${e.target.checked}`
												)

											})
											dispatch({
												type: 'SET_IGNORE_NUTRIENTS_EXCESS',
												ignoreExcess: e.target.checked,
												nutrients
											})
										}}
										onChange={()=>{}}
									/>
								</div>
							</span>
						</>
					},
					...nutrients.map(nutrient => {
						return {
							content: <>
								<span className="flex_grow_1">{nutrient}</span>
								<span className="flex_grow_1">
									<input
										type="tel" pattern="[0-9]*"
										placeholder="Weight"
										value={props.nutrientSettings[nutrient]?.weight}
										id={`${nutrient}_weight`}
										onChange={e => {
											const isValidWeight = e.target.value && +e.target.value >= 0
											const weight = isValidWeight ? +e.target.value : 1
											localStorage.setItem(`nutrient_${nutrient}`, `${weight},${props.nutrientSettings[nutrient].ignoreExcess}`)
											dispatch({
												type: 'SET_NUTRIENT_WEIGHT',
												weight: isValidWeight ? weight : undefined, 
												nutrient
											})
										}}
										onBlur={e => { 
											e.target.value = props.nutrientSettings[nutrient]?.weight >= 0 ? props.nutrientSettings[nutrient]?.weight : 1
										}}
									/>
								</span>
								<span className="flex justify_center flex_grow_1">
									<input
										type="checkbox"
										id={`${nutrient}_excess_ignore`}
										onChange={e => {
											localStorage.setItem(`nutrient_${nutrient}`, `${props.nutrientSettings[nutrient]?.weight},${e.target.checked}`)

											dispatch({
												type: 'SET_IGNORE_NUTRIENT_EXCESS',
												nutrient,
												ignoreExcess: e.target.checked
											})
										}}
										checked={
											props.nutrientSettings[nutrient]
											? props.nutrientSettings[nutrient].ignoreExcess || false
											: false
										}
									/>
								</span>
							</>,
							flex: true
						}
					})
				]}
			/>
		</div>
	)
}

export default NutrientSettings