import React from 'react'
import ReactDOM from 'react-dom'

import App from './views/App/App.jsx'

window.addEventListener('DOMContentLoaded', e => {
	ReactDOM.render(
		<App/>,
		document.getElementById('root')
	)
})
