import React, { useState } from 'react'
import ingredients from '../../consts/Ingredients/meta.js'
import { 
	sigFigs, calculateMixNutrients,
	calculateMixNutrients100Grams, calculateMER,
	lbsToKgs, kgsToLbs, getRequirementSet, printResults
} from '../../utils.js'

import { GRANULARITY, NUTRIENT_UNITS } from '../../consts.js'

import Select from '../../components/Select/Select.jsx'
import Input from '../../components/Input/Input.jsx'
import MERPreview from '../MERPreview/MERPreview.jsx'

const RecommendedMixAmount = ({ dailyCalories, mixNutrients100Grams }) => {
	const mixCaloriesPerGram = mixNutrients100Grams.Calories / 100
	const gramsToMeetDailyCalReq = dailyCalories / mixCaloriesPerGram
	const lbsToMeetDailyCalReq = kgsToLbs(gramsToMeetDailyCalReq / 1000)
	return <div className="MixAnalytics">
		<div>Calories per 100 grams in this mix: {sigFigs(mixNutrients100Grams.Calories, 2)}kcals</div>
		<div>Recommended mix per day: {sigFigs(lbsToMeetDailyCalReq, 2)}lbs = {sigFigs(gramsToMeetDailyCalReq, 2)}g</div>
	</div>
}

const Recipe = ({ recipe, dispatch, index=-1 }) => {
	const mer = calculateMER(recipe.settings)
	const currentWeightKgs = lbsToKgs(recipe.settings.currentWeight)
	const adjustedWeight = currentWeightKgs ** 0.75
	const dailyCalories =  mer * adjustedWeight
	const mixNutrients = calculateMixNutrients(recipe.ingredients)
	const mixNutrients100Grams = calculateMixNutrients100Grams(recipe.ingredients)
	const requirementSet = getRequirementSet(recipe.settings)[1]
	const requirementSetKeys = Object.keys(requirementSet).reverse()
	requirementSetKeys.push('Calories')
	const nutritionalValues = (
		Object.keys(mixNutrients100Grams).filter(
			nutrient => requirementSet[nutrient] || mixNutrients100Grams[nutrient]
		).sort((a, b) => (
			requirementSetKeys.indexOf(b) - requirementSetKeys.indexOf(a)
		))
	)

	const selectedIngredients = Object.keys(recipe.ingredients).sort(
		(a, b) => recipe.ingredients[b] - recipe.ingredients[a]
	)

	const [editingTitle, setEditingTitle] = useState(false)

	const [newTitle, setNewTitle] = useState('')

	const isUnsavedRecipe = index < 0

	return <div className="Recipe" id="proportions_and_nutrition">
		<div className="saved_recipe_header flex space_between">
			<div className="recipe_metadata">
				{
					index >= 0
					? <div className="flex">
						<h1>
							{recipe.title}
							&nbsp;
							<span 
								title={`${editingTitle ? 'Close' : 'Open'} edit`}
								className={`interactive place_self_center icon ${editingTitle ? 'rotate_180' : 'rotate_90'}`}
								onClick={e => {
									setEditingTitle(!editingTitle)
									setNewTitle(recipe.title)
								}}
							>&#9998;</span>
						</h1>
					</div>
					: null
				}
				{
					// show the edit input if it's an unsaved or the 
					// edit icon has been clicked
					isUnsavedRecipe || editingTitle
					? <div className="name_editor">
						<Input
							label="Recipe name" 
							placeholder="Enter recipe name"
							onChange={e => setNewTitle(e.target.value)}
							defaultValue={recipe.title}
						/>
						<button 
							className={
								newTitle && newTitle !== recipe.title
								? '' 
								: 'disabled'
							}
							onClick={!newTitle ? null : e => {
								if(index < 0) {
									dispatch({
										type: 'ADD_RECIPE',
										recipe: {
											...recipe,
											title: newTitle,
											saved: Date.now()
										}
									})
								} else {
									dispatch({
										type: 'UPDATE_RECIPE',
										recipe: index,
										title: newTitle
									})
								}
								setEditingTitle(false)

							}}
						>Save</button>
						{
							isUnsavedRecipe
							? null
							: <button
								onClick={e => setEditingTitle(false)}
							>
								Cancel
							</button>
						}
					</div>
					: null
				}
				{recipe.dog ? <h3>Dog: {recipe.dog}</h3> : null}
				<MERPreview mer={mer} settings={recipe.settings}/>
				<RecommendedMixAmount 
					dailyCalories={dailyCalories} 
					mixNutrients100Grams={mixNutrients100Grams}
				/>
			</div>
			<div className="recipe_actions">
			{
				isUnsavedRecipe
				? <button 
					className="margin_left_auto"
					onClick={e => printResults(newTitle)}
				>Download</button>
				: <>
					<Select 
						options={[
							{
								title: 'Apply settings',
								content: 'Apply settings',
								onClick: e => {
									// set general settings, nutrient settings, and 
									// ingredient settings from saved recipe
									dispatch([
										{
											type: 'SET_SETTINGS',
											settings: recipe.settings,
											nutrientSettings: recipe.nutrientSettings,
											ingredientSettings: recipe.ingredientSettings
										},
										{
											type: 'NAVIGATE',
											view: 'balancer'
										},
										{
											type: 'SET_BALANCER_VIEW',
											view: 'ingredients'
										}
									])
								}
							},
							{
								title: 'Delete',
								content: 'Delete',
								onClick: e => {
									dispatch([
										{ type: 'SELECT_RECIPE', index: -1 },
										{ type: 'DELETE_RECIPE', index }
									])
								}
							},
							{
								title: 'Download',
								content: 'Download',
								onClick: e => printResults()
							}
						]}
					/>
				</>
			}
			</div>
		</div>
		<div>

			<div className="flex proportions_and_nutrition">
				<span className="final_proportions flex_grow_1">
					<h3>Ingredient Proportions</h3>
					{selectedIngredients.map(ingredient => {
						const ingredientProportion = sigFigs(recipe.ingredients[ingredient] / GRANULARITY, 2)
						const title = ingredients[ingredient][0]
						return <div className={`flex${recipe.ingredients[ingredient] ? '' : ' unused_ingredient'}`} key={`ingredient_${ingredient}_proportion_row`}>
							<span>{title}</span>
							<span className="margin_left_auto">{ingredientProportion}%</span>
						</div>
					})}
				</span>
				<span className="nutritional_label flex_grow_1">
					<h3>Nutritional Values</h3>
					<div className="flex">
						<span>Per 100 grams</span>
						<span>Amount</span>
						<span className="margin_left_auto">DV(%)</span>

					</div>
					{
						nutritionalValues.map(nutrient => {
							const requiredRange = requirementSet[nutrient] || []
							const requiredAmount = requiredRange[0] || 0
							const mixAmount = mixNutrients100Grams[nutrient] || 0
							const amountString = `${sigFigs(mixAmount, 2)} ${requiredRange[requiredRange.length-1] || NUTRIENT_UNITS[nutrient]?.toLowerCase() || ''}`
							const fitnessString = requiredAmount ? `${sigFigs(((mixNutrients[nutrient] || 0) / requiredAmount) * 100, 2)}%` : ''

							return <div className="flex" key={`nutrient_${nutrient}_amount`}>
								<span className="nutrient_name">{nutrient}</span>
								<span>{amountString}</span>
								<span className="margin_left_auto">{fitnessString}</span>
							</div>
						})
					}
				</span>
			</div>
		</div>
		<iframe 
			title="nutrient_print_frame"
			name="print_frame" 
			width="0" 
			height="0" 
			frameBorder="0" 
			src="about:blank"
		/>
	</div>
}

export default Recipe