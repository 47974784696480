import React from 'react'

const Input = ({
	label,
	id,
	useRef,
	error,
	setError,
	defaultValue,
	placeholder,
	onChange
}) => {
	const clearError = error ? (() => setError('')) : null
	return <div className={`Input${error ? ' invalid_input' : ''}`}>
		<label htmlFor={id}>{label}</label>
		<input 
			id={id} 
			ref={useRef} 
			onClick={clearError} 
			onFocus={clearError} 
			onChange={e => {
				onChange(e)
				if(clearError) {
					clearError()
				}
			}}
			placeholder={placeholder}
		/>
		{error ? <div>{error}</div> : null}
	</div>
}

export default Input