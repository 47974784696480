import React, { useEffect, useState, useRef } from 'react'

import Select from '../../components/Select/Select.jsx'
import List from '../../components/List/List.jsx'

import ingredients from '../../consts/Ingredients/meta.js'

const ingredientMatchesSearch = (ingredient, search) => (
	ingredient.toLowerCase().indexOf(search.toLowerCase().trim()) > -1
)

const searchFilterIngredients = (ings, search) => {
	return ings.filter(
		ingredient => (
			ingredientMatchesSearch(ingredient, search)
			|| ingredientMatchesSearch(ingredients[ingredient][0], search)
		)
	)
}

const AvailableIngredients = ({ props, dispatch, selectedIngredients, availableIngredients, search, setSearch }) => {
	const filteredIngredients = searchFilterIngredients(availableIngredients, search)
	const inputRef = useRef()
	const updateSearch = e => setSearch(e.target.value)
	return (
		<span className="AvailableIngredients hide_overflow">
			<div className="flex">
				<h4>Available ({filteredIngredients.length}/{availableIngredients.length})</h4>
				<span 
					className="interactive margin_left_auto interactive_text"
					onClick={e => dispatch({
						type: 'SELECT_INGREDIENTS',
						ingredients: availableIngredients
					})}
				>&oplus; Add All</span>
			</div>
			<span className="flex relative">
				<input 
					className="availableIngredientsSearch"
					placeholder="Search ingredients"
					type="text"
					ref={inputRef}
					onChange={updateSearch}
				/>
				<span 
					title="Clear search"
					className="inputClearIcon rotate_45 interactive heightFitContent absolute" 
					onClick={e => {
						setSearch('')
						inputRef.current.value = ''
						inputRef.current.focus()
					}}
				>&oplus;</span>				
			</span>
			<List rows={
				filteredIngredients.length
				? filteredIngredients.map((ingredient, i) => {
					const selected = selectedIngredients.includes(ingredient)
					return {
						onClick: e => dispatch({
							type: `${selected ? 'DE' : ''}SELECT_INGREDIENT`,
							ingredient
						}),
						content: <>
							<span>{ingredients[ingredient][0]}</span>
							<input 
								className="margin_left_auto"
								type="checkbox" 
								checked={selected}
								onChange={() => {}}
							/>
						</>,
						flex: true
					}
				})
				: ['No available ingredients for give search']
			}/>
		</span>
	)
}

const SelectedIngredientRow = ({ dispatch, ingredient, ingredientsSettings }) => {
	const [settingOpen, setSettingsOpen] = useState(false)
	return <>
		<div className="flex" key={`sel_ing_${ingredient}`}>
			<span className="selected_ingredient_row_title">{ingredients[ingredient][0]}</span>
			<span className="interactive" onClick={e => setSettingsOpen(!settingOpen)}>&#9881;</span>
			<span
				className="red_text interactive margin_left_auto nowrap" 
				onClick={e => dispatch({
					type: 'DESELECT_INGREDIENT',
					ingredient
				})}
			>{String.fromCharCode(8854)} Remove</span>
		</div>
		{
			settingOpen
			? <div className="flex flex_wrap ingredient_settings">
				<span>
					<label htmlFor={`${ingredient}_minimum`}>Min</label>
					<input 
						id={`${ingredient}_minimum`} 
						placeholder="% Minimum"
						type="tel" pattern="[0-9]*"
						min={0}
						max={100}
						defaultValue={
							ingredientsSettings[ingredient].min
						}
						onBlur={e => { 
							e.target.value = (
								ingredientsSettings[ingredient].min
							)
						}}
						onChange={e => dispatch({
							type: 'SET_INGREDIENT_MIN',
							ingredient: ingredient,
							minimum: e.target.value && +e.target.value >= 0 ? +e.target.value : 0
						})}
					/>
				</span>
				<span>
					<label htmlFor={`${ingredient}_maximum`}>Max</label>
					<input 
						id={`${ingredient}_maximum`} 
						placeholder="% Maximum"
						min={0}
						max={100}
						type="tel" pattern="[0-9]*"
						defaultValue={
							ingredientsSettings[ingredient]?.max
						}
						onBlur={e => { 
							e.target.value = (
								ingredientsSettings[ingredient]?.max
							)
						}}
						onChange={e => dispatch({
							type: 'SET_INGREDIENT_MAX',
							ingredient: ingredient,
							maximum: e.target.value && +e.target.value >= 0 ? +e.target.value : 100
						})}
					/>
				</span>
			</div>
			: null
		}
	</>
}

const SelectedIngredients = ({ props, dispatch, selectedIngredients, availableIngredients, search, setSearch }) => {
	const ingredientsSettings = props.ingredientsSettings
	const filteredIngredients = searchFilterIngredients(selectedIngredients, search)
	const inputRef = useRef()

	return (
		<span className="SelectedIngredients hide_overflow">
			<div className="flex">
				<h4 htmlFor="selected_ingredients">Selcted ({filteredIngredients.length}/{selectedIngredients.length})</h4>
				<span 
					className="interactive margin_left_auto interactive_text"
					onClick={e => dispatch({
						type: 'CLEAR_SELECTED_INGREDIENTS'
					})}
				>{String.fromCharCode(8854)} Remove All</span>
			</div>
			<span className="flex relative">
				<input 
					className="selectedIngredientsSearch"
					placeholder="Search ingredients"
					ref={inputRef}
					onChange={e => setSearch(e.target.value)}
				/>
				<span 
					title="Clear search"
					className="inputClearIcon rotate_45 interactive absolute" 
					onClick={e => {
						setSearch('')
						inputRef.current.value = ''
						inputRef.current.focus()
					}}
				>&oplus;</span>
			</span>
			<List rows={
				filteredIngredients.length 
				? filteredIngredients.map((ingredient, i) => {
					return {
						content: <SelectedIngredientRow 
							dispatch={dispatch} 
							ingredient={ingredient} 
							ingredientsSettings={ingredientsSettings}
						/>
					}
				})
				: selectedIngredients.length ? [{content: 'No selected ingredients for given search'}]
				: [{content: 'Use all'}]
			}/>
		</span>
	)
}

const IngredientsSelector = ({ props, dispatch, availableIngredients }) => {

	const selectedIngredients = Object.keys(props.ingredientsSettings).filter(
		ingredient => props.ingredientsSettings[ingredient].selected
	)
	const [selectedSearch, setSelectedSearch] = useState('')
	const [availableSearch, setAvailableSearch] = useState('')

	const [nameError, setNameError] = useState('')

	const setNameInputRef = useRef()
	const addSet = () => {
		const setName = (setNameInputRef?.current?.value || '').trim()
		if(!selectedIngredients.length) {
			// SHOW ERROR: ADD SOME INGREDIENTS!!
			setNameError('Select ingredients')
		} else if(!setName) {
			// SHOW ERROR: NAME REQUIRED
			setNameError('Name required')
		} else {
			// ADD THE SET!
			dispatch({
				setName,
				type: 'PUT_INGREDIENT_SET',
				ingredients: selectedIngredients
			})
		}
	}

	useEffect(() => {
		localStorage.setItem('selectedIngredients', JSON.stringify(selectedIngredients))
		if(nameError === 'Select ingredients' && selectedIngredients.length) {
			setNameError('')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedIngredients, selectedIngredients.length])

	const options = Object.keys(props.ingredientSets).map(setName => {
		const setIngredients = props.ingredientSets[setName]
		const allSetIngredientsSelected = setIngredients.every(
			ingredient => props.ingredientsSettings[ingredient].selected
		)
		return {
			title: setName,
			value: setName,
			onClick: (e, val) => {
				e.stopPropagation()
				dispatch({
					type: allSetIngredientsSelected ? 'DESELECT_INGREDIENTS' : 'SELECT_INGREDIENTS',
					ingredients: setIngredients
				})
			},
			content: <>
				<input 
					type="checkbox"
					checked={allSetIngredientsSelected}
				/>
				<span className="select_ingredient_option_title">{`[${setIngredients.length}] ${setName}`}</span>
				<span 
					className="interactive red_text add_ingredient_set_icon"
					title="Delete set"
					onClick={e => {
						e.stopPropagation()
						dispatch({
							type: 'DELETE_INGREDIENT_SET',
							setName
						})
					}}
				>&#8854;</span>
			</>
		}
	})
	options.unshift({
		title: 'Add set',
		value: undefined,
		content: <form 
			className={`add_ingredient_set_row${nameError ? ' invalid_input' : ''}`}
			onClick={e => e.stopPropagation()}
			onSubmit={e => {
				e.preventDefault()
				addSet()
			}}
		>
			<div>Add selected as set</div>
			<div className="flex">
				<input 
					placeholder="Set name"
					ref={setNameInputRef}
					onChange={e => {
						if(nameError) {
							setNameError('')
						}
					}}
					onClick={e => {
						if(nameError) {
							setNameError('')
						}
					}}
				/>
				<span 
					className="interactive interactive_text add_ingredient_set_icon"
					onClick={e => {
						addSet()
					}}
				>&oplus;</span>
			</div>
			{
				nameError
				? <div className="small_text">{nameError}</div>
				: null
			}
		</form>
	})

	return <div className="IngredientsSelector">
		<Select
			options={options}
			updateHeight={`${Object.keys(props.ingredientSets).length}${nameError}`}
			disabledSelect={true}
		/>
		<div className="flex flex_grow lists">
			<AvailableIngredients
				search={availableSearch}
				setSearch={setAvailableSearch}
				props={props} 
				dispatch={dispatch} 
				availableIngredients={availableIngredients} 
				selectedIngredients={selectedIngredients}
			/>
			<SelectedIngredients
				search={selectedSearch}
				setSearch={setSelectedSearch}
				props={props} 
				dispatch={dispatch} 
				availableIngredients={availableIngredients} 
				selectedIngredients={selectedIngredients}
			/>
		</div>
	</div>
}

export default IngredientsSelector