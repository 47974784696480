import React, { useState } from 'react'

import Tabs from '../../components/Tabs/Tabs.jsx'

const tabOptions = ['motivation', 'sources', 'method', 'disclaimer', 'contact', 'roadmap']


const Motivation = () => {
	return <p>More and more dog owners are realizing the benefits of feeding fresh homemade food to their dogs. However, many of those inclined to pursue this undertaking are scared away by warning of imbalanced diets. Online resources for how to create a balanced diet based on the most current research are sparse. After running into this, I decided to learn as much as I could about canine nutrition and develop software to allow anyone to easily create balanced diets for their furry companions without fear of malnutrition.</p>
}

const Sources = () => {
	return <>
		<p>Two primary resources were utilized in the development of the underlying balancing algorithm:</p>
		<ol>
			<li><a target="_blank" href="https://www.nap.edu/catalog/10668/nutrient-requirements-of-dogs-and-cats" rel="noreferrer">Nutrient Requirements of Dogs and Cats; National Research Council (NRC), 2006</a></li>
			<li><a target="_blank" href="https://fediaf.org/images/FEDIAF_Nutritional_Guidelines_2020_20200917.pdf" rel="noreferrer">Nutrient Guidelines For Complete and Complementary Pet Food for Cats and Dogs; European Pet Food Industry Federation (FEDIAF), 2020</a></li>
		</ol>
		<p>Data for the ingredients comes from <a target="_blank" href="https://www.nutritionvalue.org/" rel="noreferrer">nutritionvalue.org</a>. The only exceptions to this are bone meal from <a target="_blank" href="https://www.feedipedia.org/node/11877" rel="noreferrer">feedipedia.org</a> and any supplement mixes which are manually formatted from the manufacturer's publicly available nutrition data.</p>
	</>
}

const Method = () => {
	return <>
		<p>Nutritional requirements are based on recommended amounts (RA) per 1000kcal of food (section 3.2.2, table III-3b<a target="_blank" href="https://fediaf.org/images/FEDIAF_Nutritional_Guidelines_2020_20200917.pdf" rel="noreferrer">[2]</a>). For puppies these values are consistent regardless of daily kcals. For adults, the RA values are modified based on the estimated maintanance energy requirement (MER). MER is calculated by modifying a base MER dependent on the dog's level of activity, age, fitness, and breed. If the dog is not pregnant or sucking puppies, the base MER is assumed to be 95 kcal per kg body weight 0.75. If pregnant, the base MER is a function of dog weight and weeks into pregnancy. If lactating, the base MER if a function of dog weight, number of puppies, and weeks into lactation.</p>
		<p>The FEDIAF publication has two cateogories of RA for adults, one for dogs at 95 MER and the other for those at 110 MER. To get a more accurate recommendation, these two points are linearly extrapolated to get a function that takes MER as the input and gives RA as the output. The dog's estimated MER is then used to get an estimated RA for each nutrient specific to that animal. These RA can then be used to determine how nutritionally ideal a given mix of ingredients is for that dog.</p>
		<p>Estimated total fitness is calculated by averaging out the fitness of each nutrient. Excess can be ignored, in which case the nutrient is considered at 100% if it is as or above 100%. Nutrients can also be given a weight, which is how much that nutrient's fitness should influence the final fitness value. For example, having a weight of 10 makes that nutrient's fitness is counted 5 times more than nutrients with a weight of 2.</p>
		<p>The balance algorithm performs the following protocol:</p>
		<ol>
			<li>Calculate the fitness of the current mix</li>
			<li>Exchange a proportion of ingredient A for ingredient B</li>
			<li>Caculate the new mix's fitness</li>
			<li>If the mix is more balanced, keep the new mix and go back to step 1</li>
			<li>If it's less balanced, don't keep the new mix and move on to the next ingredient B (if there is one) and go back to step 1</li>
			<li>Once ingredient A is balanced against every other ingredient, make ingredient A the next ingredient (if there is one) and go back to step 1</li>
		</ol>
		<p>The above protocol is repeated until no change in the mix can be found to improve its fitness.</p>
	</>
}

const Disclaimer = () => {
	return <p>Always consult your vet before changing your dog's diet. This site was built by me, Jacob Penney, a dog lover and programmer. I'm not an expert in the science of animal nutrition. Claims on this site should only serve as the starting point to your journey to developing a recipe for your own dogs.</p>
}

const Contact = () => {
	return <p>If you find bugs or have any feedback, questions, or requests for new ingredients or features, feel free to reach out at <a href="mailto:jacobpenneyofficial@gmail.com">jacobpenneyofficial@gmail.com</a>!</p>		
}

const Roadmap = () => {
	return <ul>
		<li><s>Saving named settings sets</s></li>
		<li><s>Saving named ingredient sets</s></li>
		<li><s>Saving recipes</s></li>
		<li><s>Recipe Book: saved recipes page</s></li>
		<li>
			Informational pages: 
			<ul>
				<li>Nutrients</li>
				<li>Ingredients</li>
			</ul>
		</li>
	</ul>
}

const About = ({ props, dispatch }) => {
	const [view, setView] = useState('motivation')
	return <div className="About">
		<h1>About</h1>
		<Tabs 
			options={tabOptions}
			view={view}
			setView={setView}
		/>
		<div className="content">{
			view === 'motivation' ? <Motivation />
			: view === 'sources' ? <Sources />
			: view === 'method' ? <Method />
			: view === 'disclaimer' ? <Disclaimer />
			: view === 'contact' ? <Contact />
			: view === 'roadmap' ? <Roadmap />
			: null
		}</div>
	</div>
}

export default About