import React, { useRef, useState } from 'react'

import Input from '../../components/Input/Input.jsx'

/*
Login steps:
1. Give backend email, get salt
2. Salt and hash password
3. Send hash to server for validation
*/

const LoginForm = ({props, dispatch, setView}) => {
	const emailInput = useRef()
	const [emailError, setEmailError] = useState('')

	const passwordInput = useRef()
	const [passwordError, setPasswordError] = useState('')

	return <div className="LoginForm">
		<form
			onSubmit={e => {
				e.preventDefault()

				const email = emailInput.current.value
				if(!email) {
					setEmailError('Email required')
				}

				const password = passwordInput.current.value
				if(!password) {
					setPasswordError('Password required')
				}
				if(!email || !password) {
					return
				}


			}}
		>
			<h2>Login</h2>
			<Input 
				id="login_email"
				useRef={emailInput}
				label='Email'
				error={emailError}
				setError={setEmailError}
			/>
			<Input 
				id="login_password"
				useRef={passwordInput}
				label='Password'
				error={passwordError}
				setError={setPasswordError}
			/>
			<button type="submit">Submit</button>
		</form>
		<div className="interactive_text" onClick={e => setView('register')}>Register account</div>
	</div>
}

export default LoginForm