import React, { useEffect } from 'react'

import AdvancedSettings from '../AdvancedSettings/AdvancedSettings.jsx'

import MERPreview from '../MERPreview/MERPreview.jsx'

import Tooltip from '../../components/Tooltip/Tooltip.jsx'
import Select from '../../components/Select/Select.jsx'

import { validateDogSettings } from '../../utils.js'

const YearsInput = ({ dispatch, props }) => {
	const yearsInputRef = React.useRef()
	useEffect(() => {
		const newVal = (
			props.settings.years === undefined 
			? '' 
			: props.settings.years
		)
		yearsInputRef.current.value = newVal
		localStorage.setItem('age', newVal)
		if(props.errors.age) {
			dispatch({
				type: 'CLEAR_AGE_ERROR'
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.selectedDog, dispatch])
	return <span className={`YearsInput${props.errors.age ? ' invalid_input' : ''}`}>
		<label htmlFor="age_input">Age (years)</label>
		<input
			id="age_input"
			type="tel" pattern="[0-9]*"
			placeholder="Years"
			ref={yearsInputRef}
			defaultValue={props.settings.years}
			onChange={e => {
				localStorage.setItem('age', e.target.value)
				const actions = [
					{
						type: 'UPDATE_YEARS',
						years: e.target.value && +e.target.value >= 0 ? +e.target.value : undefined
					},
					{
						type: 'UPDATE_MER'
					}
				]
				if(props.errors.age) {
					actions.push({
						type: 'CLEAR_AGE_ERROR'
					})
				}
				dispatch(actions)
			}}
			onBlur={e => {
				if(typeof props.settings.years === 'number') {
					e.target.value = props.settings.years
				}
			}}
		/>
		{
			props.errors.age
			? <div className="small_text">{props.errors.age}</div>
			: null
		}
	</span>
}

const CurrentWeightInput = ({ dispatch, props }) => {
	const currentWeightInputRef = React.useRef()

	useEffect(() => {
		const newVal = (
			props.settings.currentWeight === undefined 
			? '' 
			: props.settings.currentWeight
		)
		currentWeightInputRef.current.value = newVal
		localStorage.setItem('currentWeight', newVal)
		if(props.errors.weight) {
			dispatch({
				type: 'CLEAR_WEIGHT_ERROR'
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.selectedDog, dispatch])
	return <span className={`CurrentWeightInput${props.errors.weight ? ' invalid_input' : ''}`}>
		<label htmlFor="current_weight_input">Weight (lbs)</label>
		<input 
			ref={currentWeightInputRef}
			id="current_weight_input"
			type="tel" pattern="[0-9]*"
			placeholder="Pounds"
			defaultValue={props.settings.currentWeight}
			onChange={e => {
				localStorage.setItem('currentWeight', e.target.value)
				const actions = [
					{
						type: 'SET_ANIMAL_WEIGHT',
						weight: +e.target.value
					},
					{
						type: 'UPDATE_MER'
					}
				]
				if(!props.errors.weight) {
					actions.push({
						type: 'CLEAR_WEIGHT_ERROR'
					})
				}
				dispatch(actions)
			}}
			onBlur={e => {
				const weight = (
					(e.target.value && +e.target.value >= 0) 
					? +e.target.value 
					: undefined
				);
				dispatch({
					type: 'SET_ANIMAL_WEIGHT',
					weight
				});
				if(typeof weight === 'number') {
					e.target.value = weight
				}
			}}
		/>
		{
			props.errors.weight
			? <div className="small_text">{props.errors.weight}</div>
			: null
		}
	</span>
}

const AdultWeightInput = ({ dispatch, props }) => {
	const adultWeightInputRef = React.useRef()

	useEffect(() => {
		const newVal = (
			props.settings.adultWeight === undefined 
			? '' 
			: props.settings.adultWeight
		)
		adultWeightInputRef.current.value = newVal
		localStorage.setItem('adultWeight', newVal)
		if(props.errors.adultWeight) {
			dispatch({
				type: 'CLEAR_ADULT_WEIGHT_ERROR'
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.selectedDog, dispatch])
	return <span className={`AdultWeightInput${props.errors.adultWeight ? ' invalid_input' : ''}`}>
		<label>Adult Weight(kg)</label>
		<input
			ref={adultWeightInputRef}
			id="adult_weight_input"
			type="tel" pattern="[0-9]*"
			placeholder="Adult Weight"
			defaultValue={props.settings.adultWeight}
			onChange={e => {
				localStorage.setItem('adultWeight', e.target.value)
				const actions = [
					{
						type: 'SET_ANIMAL_ADULT_WEIGHT',
						weight: Math.max(0, +e.target.value || 0)
					},
					{
						type: 'UPDATE_MER'
					}
				]
				if(props.errors.adultWeight) {
					actions.push({
						type: 'CLEAR_ADULT_WEIGHT_ERROR'
					})
				}
				dispatch(actions)
			}}
			onBlur={e => {
				const weight = e.target.value && +e.target.value >= 0 ? +e.target.value : undefined
				dispatch({
					type: 'SET_ANIMAL_ADULT_WEIGHT',
					weight
				})
				e.target.value = weight || ''
			}}
		/>
		{
			props.errors.adultWeight
			? <div className="small_text">{props.errors.adultWeight}</div>
			: null
		}
	</span>
}

const EarlyGrowthCheckbox = ({ dispatch, props }) => {
	const checkboxRef = React.useRef()
	useEffect(() => {
		checkboxRef.current.value = props.settings.inEarlyGrowth
		localStorage.setItem('inEarlyGrowth', props.settings.inEarlyGrowth)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.selectedDog])
	return <div
		className="EarlyGrowthCheckbox flex interactive"
		onClick={e => {
			localStorage.setItem('inEarlyGrowth', !props.settings.inEarlyGrowth)
			dispatch({
				type: `${props.settings.inEarlyGrowth ? 'UN' : ''}CHECK_EARLY_GROWTH`
			})
		}}
	>
		<input
			ref={checkboxRef}
			type="checkbox"
			checked={props.settings.inEarlyGrowth}
			onChange={() => {}}
		/>
		&nbsp;
		<span>Less than 14 weeks of age</span>
	</div>
}

const validateSaveDogSettings = ({ props, dispatch }) => {
	const invalidFieldsErrorDisplayActions = validateDogSettings({ props, dispatch })
	if(!props.settings.name) {
		invalidFieldsErrorDisplayActions.push({ 
			type: 'SET_DOG_NAME_ERROR', 
			error: 'Name required to save' 
		})
	}
	return invalidFieldsErrorDisplayActions
}

const savedDogMatchesSelected = ({ props, dispatch }) => {
	const selectedDog = (
		props.selectedDog 
		? props.dogs.find(dog => dog.name === props.selectedDog) 
		: undefined
	)
	return (
		props.selectedDog 
		? Object.keys(selectedDog).every(
			setting => selectedDog[setting] === props.settings[setting]
		)
		: false
	)
}

const saveDog = ({ props, dispatch, invalidFieldsErrorDisplayActions }) => {
	if(invalidFieldsErrorDisplayActions.length) {
		dispatch(invalidFieldsErrorDisplayActions)
		return
	}
	const dogExistsWithName = props.dogs.find(dog => dog.name === props.settings.name)
	dispatch([
		{
			type: (
				props.selectedDog || dogExistsWithName
				? 'EDIT_DOG_SETTINGS_SET'
				: 'ADD_DOG_SETTINGS_SET'
			)
		},
		{
			type: 'SELECT_DOG_SETTINGS', 
			name: props.settings.name
		},
		{ type: 'UPDATE_MER' }
	])
}

const SaveDogSettingsButton = ({ 
	props, dispatch, 
	invalidFieldsErrorDisplayActions, 
	savedMatchesCurrent 
}) => {
	return <div className="SaveDogSettingsButton">
		<button 
			className={invalidFieldsErrorDisplayActions.length || savedMatchesCurrent ? "disabled" : ''}
			onClick={e => saveDog({ 
				props, dispatch, 
				invalidFieldsErrorDisplayActions 
			})}
		>Save</button>
		{
			savedMatchesCurrent
			? <div className="info_text small_text"><i>No unsaved changes</i></div>
			: null
		}
	</div>
}

const DogSelect = ({ 
	props, dispatch, 
	invalidFieldsErrorDisplayActions, 
	savedMatchesCurrent 
}) => {
	const dogNames = props.dogs.map(dog => dog.name)
	dogNames.unshift('Add dog')
	return (
		<span className="DogSelect">
			<label htmlFor="dog_select">Select Dog</label>
			<Select 
				updateHeight={dogNames.length}
				options={dogNames.map(
					(name, i) => {
						const selected = name === props.selectedDog
						return {
							content: (
								<div 
									key={`dog_named_${name}`} 
									value={i ? name : undefined}
									title={selected ? 'selected' : name}
									onClick={selected ? e => e.stopPropagation() : null}
									className={`flex dog_option${selected ? '' : ' interactive'}`}
								>
									<div className="dog_option_text">{name}</div>
									{
										i 
										? <div 
											className="interactive red_text dog_option_delete_icon"
											title="remove"
											onClick={e => {
												e.stopPropagation()
												const actions = [{
													type: 'DELETE_DOG_SETTINGS_SET',
													name
												}]
												if(selected) {
													actions.push({
														type: 'SELECT_DOG_SETTINGS',
														name: undefined
													})
													actions.push({ type: 'UPDATE_MER' })
												}
												dispatch(actions)
											}}
										>&#8854;</div> 
										: <div 
											className="interactive_text dog_option_delete_icon"
											title="add"
										>&oplus;</div>
									}
								</div>
							),
							title: name,
							value: i ? name : undefined
						}
					}
				)}
				onChange={(e, val) => {
					if(val !== props.selectedDog) {
						dispatch([
							{
								type: 'SELECT_DOG_SETTINGS',
								name: val
							},
							{ type: 'UPDATE_MER' }
						])
					} else if(val === undefined && val === props.selectedDog) {
						// attempt to save
						saveDog({ 
							props, dispatch, 
							invalidFieldsErrorDisplayActions
						})
					}
				}}
				value={props.selectedDog}
			/>
		</span>
	)
}

let focusing = false
const DogNameInput = ({ props, dispatch }) => {
	const inputRef = React.useRef()
	useEffect(() => {
		const newVal = (
			props.settings.name === undefined 
			? '' 
			: props.settings.name
		)

		inputRef.current.value = newVal
		const actions = [{
			type: 'SET_DOG_NAME',
			name: newVal
		}]
		if(props.errors.name) {
			actions.push({
				type: 'SET_DOG_NAME_ERROR', 
				error: null
			})
		}
		dispatch(actions)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.selectedDog, dispatch])
	const handleFocus = e => {
		if(focusing) {
			return
		}
		dispatch({
			type: 'SET_DOG_NAME_ERROR', 
			error: null
		})
	}

	useEffect(() => {
		if(props.errors.name) {
			focusing = true
			inputRef.current.focus()
			focusing = false
		}
	}, [props.errors.name])
	return (
		<span className={`DogNameInput${props.errors.name ? ' invalid_input' : ''}`}>
			<label htmlFor="dog_name_input">Name <Tooltip tip="Only used for saving settings in association with specific dogs to easily switch between"/></label>
			<input 
				ref={inputRef}
				id="dog_name_input"
				type="text"
				onFocus={handleFocus}
				placeholder="Dog's name"
				onChange={e => {
					const name = e.target.value.trim()
					
					const actions = [{
						type: 'SET_DOG_NAME',
						name
					}]
					if(props.errors.name) {
						actions.push({
							type: 'SET_DOG_NAME_ERROR',
							error: null
						})
					}
					dispatch(actions)
				}}
				defaultValue={props.settings.name}
			/>
			{
				props.errors.name
				? <div className="small_text">{props.errors.name}</div>
				: null
			}
		</span>
	)
}

const BasicSettings = ({ props, dispatch }) => {
	return <div className="BasicSettings">
		<h3 className="flex">Basic</h3>
		<DogNameInput dispatch={dispatch} props={props}/>
		<YearsInput dispatch={dispatch} props={props}/>
		<CurrentWeightInput dispatch={dispatch} props={props}/>	
		{
			props.settings.years === 0 
			? [
				<AdultWeightInput dispatch={dispatch} props={props}/>,
				<EarlyGrowthCheckbox dispatch={dispatch} props={props}/>
			]
			: null
		}
	</div>
}

const FatnessSelect = ({ dispatch, props }) => {
	const opts = ['skinny', 'slim', 'ideal', 'overweight', 'obese']
	return <span className="FatnessSelect">
		<label>Select Fitness</label>
		
		<Select
			updateHeight={opts.length}
			options={opts.map(opt => {
				const title = `${opt[0].toUpperCase()}${opt.slice(1)}`
				return {
					content: title,
					title: title,
					value: opt
				}
			})}
			onChange={(e, val) => {
				localStorage.setItem('fitness', val)
				dispatch({
					type: 'SET_FATNESS',
					fatness: val
				})
			}}
			value={props.settings.fatness}
		/>
	</span>
}

const highImpactTip = "Daily hours running or performing other high-impact activities."
const HoursHighImpactInput = ({ props, dispatch }) => {
	const inputRef = React.useRef()
	useEffect(() => {
		const newVal = (
			props.settings.hoursHighImpactActivity === undefined 
			? '' 
			: props.settings.hoursHighImpactActivity
		)
		inputRef.current.value = newVal || 0
		localStorage.setItem('hoursRunning', newVal)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.selectedDog])
	return <span className="HoursHighImpactInput">
		<label htmlFor="hours_high_impact_input">Hours running <Tooltip tip={highImpactTip}/></label>
		<input 
			ref={inputRef}
			id="hours_high_impact_input"
			type="tel" pattern="[0-9]*"
			placeholder="Hours"
			defaultValue={props.settings.hoursHighImpactActivity || 0}
			onChange={e => {
				const hours = Math.max(0, e.target.value ? +e.target.value : 0)
				localStorage.setItem('hoursRunning', hours)
				dispatch([
					{
						type: 'UPDATE_HIGH_IMPACT_HOURS',
						hours
					},
					{
						type: 'UPDATE_MER'
					}
				])
			}}
			onBlur={e => {
				if(typeof props.settings.hoursHighImpactActivity === 'number') {
					e.target.value = props.settings.hoursHighImpactActivity || 0
				}
			}}
		/>
	</span>
}

const lowImpactTip = "Daily hours walking or performing other low-impact activities."
const HoursLowImpactInput = ({ props, dispatch }) => {
	const inputRef = React.useRef()
	useEffect(() => {
		const newVal = (
			props.settings.hoursLowImpactActivity === undefined 
			? '' 
			: props.settings.hoursLowImpactActivity
		)
		inputRef.current.value = newVal || 0
		localStorage.setItem('hoursWalking', newVal)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.selectedDog])
	return <span className="HoursLowImpactInput">
		<label htmlFor="hours_low_impact_input">Hours walking <Tooltip tip={lowImpactTip}/></label>
		<input
			ref={inputRef}
			id="hours_low_impact_input"
			type="tel" pattern="[0-9]*"
			placeholder="Hours"
			defaultValue={props.settings.hoursLowImpactActivity || 0}
			onChange={e => {
				const hours = Math.max(0, e.target.value ? +e.target.value : 0)
				localStorage.setItem('hoursWalking', hours)
				dispatch([
					{
						type: 'UPDATE_LOW_IMPACT_HOURS',
						hours
					},
					{
						type: 'UPDATE_MER'
					}
				])
			}}
			onBlur={e => {
				if(typeof props.settings.hoursLowImpactActivity === 'number') {
					e.target.value = props.settings.hoursLowImpactActivity || 0
				}
			}}
		/>
	</span>
}

const ActivitySettings = ({ props, dispatch }) => (
	<div className="ActivitySettings">
		<h3>Activity</h3>
		{
			props.settings.years
			? <FatnessSelect dispatch={dispatch} props={props}/>
			: null
		}
		<HoursLowImpactInput dispatch={dispatch} props={props}/>
		<HoursHighImpactInput dispatch={dispatch} props={props}/>

	</div>
)

const LactationCheckbox = ({ dispatch, props }) => {
	return <span
		className="LactationCheckbox flex interactive"
		onClick={e => {
			localStorage.setItem('isLactating', !props.settings.isLactating)
			if(!props.settings.isLactating) {
				localStorage.setItem('isPregnant', false)
			}

			const actions = [
				{
					type: `${props.settings.isLactating ? 'UN' : ''}CHECK_IS_LACTATING`
				},
				{
					type: 'UPDATE_MER'
				}
			]
			if(props.settings.isPregnant) {
				actions.push({
					type: 'UNCHECK_IS_PREGNANT'
				})
			}
			dispatch(actions)
		}}
	>
		<input
			type="checkbox"
			checked={props.settings.isLactating}
			onChange={() => {}}
		/>
		<span>Is suckling puppies</span>
	</span>
}

const GestationCheckbox = ({ dispatch, props }) => {
	return <span
		className="GestationCheckbox flex interactive"
		onClick={e => {
			localStorage.setItem('isPregnant', !props.settings.isPregnant)
			if(!props.settings.isPregnant) {
				localStorage.setItem('isLactating', false)
			}
			
			const actions = [
				{
					type: `${props.settings.isPregnant ? 'UN' : ''}CHECK_IS_PREGNANT`
				},
				{
					type: 'UPDATE_MER'
				}
			]
			if(props.settings.isLactating) {
				actions.push({
					type: 'UNCHECK_IS_LACTATING'
				})
			}
			dispatch(actions)
		}}
	>
		<input
			type="checkbox"
			checked={props.settings.isPregnant}
			onChange={() => {}}
		/>
		<span>Is pregnant</span>
	</span>
}

const WeeksInGestationInput = ({ props, dispatch }) => {
	const inputRef = React.useRef()
	useEffect(() => {
		const newVal = (
			props.settings.weeksInGestation === undefined 
			? '' 
			: props.settings.weeksInGestation
		)
		inputRef.current.value = newVal
		localStorage.setItem('weeksPregnant', newVal)
		if(props.errors.weeksInGestation) {
			dispatch({
				type: 'CLEAR_GESTATION_WEEKS_ERROR'
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.selectedDog, dispatch])
	return <span className={`WeeksInGestationInput${props.errors.weeksInGestation ? ' invalid_input' : ''}`}>
		<label htmlFor="weeks_in_gestation_input">Weeks pregnant</label>
		<input 
			ref={inputRef}
			id="weeks_in_gestation_input"
			type="tel" pattern="[0-9]*"
			placeholder="Weeks"
			defaultValue={props.settings.weeksInGestation}
			onChange={e => {
				localStorage.setItem('weeksPregnant', e.target.value)
				const actions = [
					{
						type: 'UPDATE_WEEKS_GESTATING',
						weeks: e.target.value ? +e.target.value : undefined
					},
					{
						type: 'UPDATE_MER'
					}
				]
				if(props.errors.weeksInGestation) {
					actions.push({
						type: 'CLEAR_GESTATION_WEEKS_ERROR'
					})
				}
				dispatch(actions)
			}}
			onBlur={e => {
				const weeks = e.target.value && +e.target.value >= 0 ? +e.target.value : undefined
				dispatch({
					type: 'UPDATE_WEEKS_GESTATING',
					weeks
				})
				if(typeof weeks === 'number') {
					e.target.value = weeks
				}
			}}
		/>
		{
			props.errors.weeksInGestation
			? <div className="small_text">{props.errors.weeksInGestation}</div>
			: null
		}
	</span>
}

const WeeksInLactationInput = ({ props, dispatch }) => {
	const inputRef = React.useRef()
	useEffect(() => {
		const newVal = (
			props.settings.weeksInLactation === undefined 
			? '' 
			: props.settings.weeksInLactation
		)
		inputRef.current.value = newVal
		localStorage.setItem('weeksLactating', newVal)

		if(props.errors.weeksInLactation) {
			dispatch({
				type: 'CLEAR_LACTATION_WEEKS_ERROR'
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.selectedDog, dispatch])
	return <span className={`WeeksInLactationInput${props.errors.weeksInLactation ? ' invalid_input' : ''}`}>
		<label htmlFor="weeks_in_lactation_input">Weeks lactating</label>
		<input 
			ref={inputRef}
			id="weeks_in_lactation_input"
			type="tel" pattern="[0-9]*"
			placeholder="Weeks"
			defaultValue={props.settings.weeksInLactation}
			onChange={e => {
				localStorage.setItem('weeksLactating', e.target.value)
				const actions = [
					{
						type: 'UPDATE_WEEKS_LACTATING',
						weeks: e.target.value ? +e.target.value : undefined
					},
					{
						type: 'UPDATE_MER'
					}
				]
				if(props.errors.weeksInLactation) {
					actions.push({
						type: 'CLEAR_LACTATION_WEEKS_ERROR'
					})
				}
				dispatch(actions)
			}}
			onBlur={e => {
				const weeks = e.target.value && +e.target.value >= 0 ? +e.target.value : undefined
				dispatch({
					type: 'UPDATE_WEEKS_LACTATING',
					weeks
				})
				if(typeof weeks === 'number') {
					e.target.value = weeks
				}
			}}
		/>
		{
			props.errors.weeksInLactation
			? <div className="small_text">{props.errors.weeksInLactation}</div>
			: null
		}
	</span>
}

const NumberOfPuppiesInput = ({ props, dispatch }) => {
	const inputRef = React.useRef()
	useEffect(() => {
		const newVal = (
			props.settings.numberOfPuppies === undefined 
			? '' 
			: props.settings.numberOfPuppies
		)
		inputRef.current.value = newVal
		localStorage.setItem('numberOfPuppies', newVal)

		if(props.errors.numberOfPuppies) {
			dispatch({
				type: 'CLEAR_NUMBER_OF_PUPPIES_ERROR'
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.selectedDog, dispatch])
	return <span className={`NumberOfPuppiesInput${props.errors.numberOfPuppies ? ' invalid_input' : ''}`}>
		<label htmlFor="number_of_puppies_input">Amount of puppies</label>
		<input 
			ref={inputRef}
			id="number_of_puppies_input"
			type="tel" pattern="[0-9]*"
			placeholder="Puppies"
			defaultValue={props.settings.numberOfPuppies}
			onChange={e => {
				localStorage.setItem('numberOfPuppies', e.target.value)
				const actions = [
					{
						type: 'UPDATE_NUMBER_OF_PUPPIES',
						count: e.target.value && +e.target.value > 0 ? +e.target.value : undefined
					},
					{
						type: 'UPDATE_MER'
					}
				]
				if(props.errors.numberOfPuppies) {
					actions.push({
						type: 'CLEAR_NUMBER_OF_PUPPIES_ERROR'
					})
				}
				dispatch(actions)
			}}
			onBlur={e => {
				const count = +e.target.value ? Math.max(1, +e.target.value) : undefined
				dispatch({
					type: 'UPDATE_NUMBER_OF_PUPPIES',
					count
				})
				if(typeof count === 'number') {
					e.target.value = count
				}
			}}
		/>
		{
			props.errors.numberOfPuppies
			? <div className="small_text">{props.errors.numberOfPuppies}</div>
			: null
		}
	</span>
}

const BitchSettings = ({ props, dispatch }) => (
	<div className="BitchSettings">
		<h3>Gestation/Lactation</h3>
		<LactationCheckbox dispatch={dispatch} props={props}/>
		<GestationCheckbox dispatch={dispatch} props={props}/>
		{
			props.settings.isPregnant
			? <WeeksInGestationInput dispatch={dispatch} props={props}/>
			: props.settings.isLactating
			? <>
				<WeeksInLactationInput dispatch={dispatch} props={props}/>
				<NumberOfPuppiesInput dispatch={dispatch} props={props}/>
			</>
			: null
		}
	</div>
)

const focusOrder = [
	'name',
	'age',
	'weight',
	'adultWeight',
	'weeksInGestation',
	'weeksInLactation',
	'numberOfPuppies'
]
const GeneralSettings = ({ props, dispatch }) => {
	useEffect(() => {
		dispatch({
			type: 'UPDATE_MER'
		})
	}, [dispatch])
	const invalidFieldsErrorDisplayActions = validateSaveDogSettings({ props, dispatch })
	const savedMatchesCurrent = savedDogMatchesSelected({ props, dispatch})
	const focusInput = focusOrder.find(field => props.errors[field])
	console.log('focusInput: ', focusInput)

	return (
		<div className="GeneralSettings">
			<div className="flex flex_wrap">
				<div className="selectors flex_grow_1">
					<div className="flex space_between">
						<div className="dogInfoSave">
							<DogSelect 
								dispatch={dispatch} 
								props={props} 
								invalidFieldsErrorDisplayActions={invalidFieldsErrorDisplayActions} 
								savedMatchesCurrent={savedMatchesCurrent}
							/>
							<SaveDogSettingsButton 
								dispatch={dispatch} 
								props={props} 
								invalidFieldsErrorDisplayActions={invalidFieldsErrorDisplayActions} 
								savedMatchesCurrent={savedMatchesCurrent}
							/>
						</div>
						<MERPreview 
							mer={props.mer} 
							settings={props.settings}
						/>
					</div>
					<BasicSettings dispatch={dispatch} props={props}/>
					<ActivitySettings dispatch={dispatch} props={props}/>
					{
						props.settings.years > 0 
						?  <BitchSettings dispatch={dispatch} props={props}/>
						: null
					}
				</div>
				<AdvancedSettings props={props} dispatch={dispatch}/>
			</div>
		</div>
	)
}

export default GeneralSettings