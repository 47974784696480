import React, { useState } from 'react'

import LoginForm from '../LoginForm/LoginForm.jsx'
import Registration from '../Registration/Registration.jsx'
import AccountRecovery from '../AccountRecovery/AccountRecovery.jsx'

const Login = ({props, dispatch}) => {
	const [view, setView] = useState('login')
	return (
		<div className="Login">{
			view === 'login' ? <LoginForm props={props} dispatch={dispatch} setView={setView}/> 
			: view === 'recover' ? <AccountRecovery props={props} dispatch={dispatch} setView={setView}/>
			: view === 'register' ? <Registration props={props} dispatch={dispatch} setView={setView}/>
			: null
		}</div>
	)
}

export default Login