import React from 'react'
import { getLang, sigFigs } from '../../utils.js'

import Recipe from '../Recipe/Recipe.jsx'

const RecipeList = ({ props, dispatch }) => {
	const recipes = props.recipes
	return <div className="RecipeList">
		<h1>Saved Recipes</h1>
		{
			recipes.length 
			? <table>
				<thead>
					<tr>
						<td>Name</td>
						<td>Dog</td>
						<td>Date saved</td>
						<td>Fitness</td>
						<td>Delete</td>
					</tr>
				</thead>
				<tbody>{recipes.map((recipe, i) => {

					return (
						<tr 
							className="interactive"
							onClick={e => {
								dispatch({
									type: 'SELECT_RECIPE',
									index: i
								})
							}}
							key={`recipe_${recipe.title.replaceAll(' ', '')}_row_${i}`}
						>
							<td>{recipe.title}</td>
							<td>{recipe.dog || '-'}</td>
							<td>{(new Date(recipe.saved)).toLocaleDateString(getLang(), {
								day: 'numeric',
								month: 'numeric',
								year: 'numeric',
								hour: 'numeric',
								minute: 'numeric'
							})}</td>
							<td>{sigFigs(recipe.fitness * 100, 2)}%</td>
							<td 
								title="delete" 
								className="red_text interactive"
								onClick={e => {
									e.stopPropagation()
									dispatch({
										type: 'DELETE_RECIPE',
										index: i
									})
								}}
							>⊖</td>
						</tr>
					)
				})}</tbody>
			</table>
			: <div>
				No recipes saved yet. Generate some and save them with the
				&nbsp;
				<span 
					className="interactive interactive_text" 
					onClick={e => dispatch({
						type: 'NAVIGATE', 
						view: 'balancer'
					})}
				>recipe generator tool</span>
			</div>
		}
	</div>
}

const Recipes = ({ props, dispatch }) => {
	return <div className="Recipes">
		{
			props.selectedRecipe >= 0
			? <div 
				className="interactive" 
				onClick={e => dispatch({type: 'SELECT_RECIPE', index: -1})}
			>&lt; saved recipes</div>
			: null
		}
		{
			props.selectedRecipe >= 0
			? <Recipe 
				recipe={props.recipes[props.selectedRecipe]} 
				index={props.selectedRecipe}
				dispatch={dispatch}
			/>
			: <RecipeList props={props} dispatch={dispatch}/>
		}
	</div>
}

export default Recipes