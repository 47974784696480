import { getRequirementSet, delay, calculateMixNutrients, copy } from './utils.js'
import { GRANULARITY } from './consts.js'

// const nutrientExcessPower = {
// 	Fat: 1.5,
// 	Sugars: 3,
// 	Carbohydrate: 3
// }

const calcExcessFitness = (fitness, ignoreExcess, nutrient, maxExceeded) => {
	const adjustedFitness = 1 - Math.tanh((fitness - 1) / 5)
	return (
		maxExceeded ? -fitness
		: ignoreExcess ? 1
		: adjustedFitness
	)
}

const donutPenalizeBelowRecomendation = ['Fat', 'Carbohydrate']
const calcFitness = (fitness, nutrient) => donutPenalizeBelowRecomendation.includes(nutrient) ? 1 : fitness ** 2

const calculateFitness = ({ nutrientSettings, mixNutrients, requirementSet }) => {
	let pool = 0, amount = 0
	const requirementNutrients = Object.keys(requirementSet)
	let i = requirementNutrients.length
	while(i--) {
		const nutrient = requirementNutrients[i]

		const nutrientSetting = nutrientSettings[nutrient] || {}
		const ignoreNutrientExcess = nutrientSetting.ignoreExcess
		const nutrientWeight = nutrientSetting.weight || 1

		const mixNutrientAmount = mixNutrients[nutrient] || 0
		const nutrientRequirement = requirementSet[nutrient][0]
		const nutrientMaximum = requirementSet[nutrient][1]
		if(nutrientRequirement) {
			const nutrientFitness = mixNutrientAmount / nutrientRequirement
			
			const maxExceeded = nutrientMaximum && nutrientMaximum !== '-' && mixNutrientAmount > nutrientMaximum

			pool += nutrientWeight * (
				nutrientFitness > 1 
				? calcExcessFitness(nutrientFitness, ignoreNutrientExcess, nutrient, maxExceeded)
				: calcFitness(nutrientFitness, nutrient)
			)
			if(isNaN(pool)) {
				debugger
			}
			amount += nutrientWeight
		}

	}
	return pool / amount
}

const swapIngredientProportion = ({
	proportions, 
	ingredient, 
	toIngredient, 
	balanceSettings,
	iterationSettings,
	dispatch, 
	requirementSet,
	nutrientSettings
}) => {
	proportions[ingredient] -= iterationSettings.delta
	proportions[toIngredient] += iterationSettings.delta
	const deltaMixNutrients = calculateMixNutrients(proportions)

	// should we keep it? Get the new fitness.
	const deltaFitness = calculateFitness({ 
		mixNutrients: deltaMixNutrients, 
		requirementSet,
		nutrientSettings
	})
	if(deltaFitness > balanceSettings.fitness) {
		balanceSettings.fitness = deltaFitness
		balanceSettings.changed = true
		dispatch({
			type: 'SET_PROPORTIONS',
			proportions: proportions,
			fitness: deltaFitness
		})
	} else {
		proportions[ingredient] += iterationSettings.delta
		proportions[toIngredient] -= iterationSettings.delta

		iterationSettings.delta /= 10
	}
}

const balanceIteration = ({ 
	dispatch, proportions, requirementSet, 
	settings, ingredientsSettings, nutrientSettings
}) => new Promise((resolve, reject) => {
	const proportionsCopy = { ...proportions }

	const mixNutrients = calculateMixNutrients(proportions)

	const requirementSet = getRequirementSet(settings)[1]
	const ingredients = Object.keys(proportionsCopy)
	let i = ingredients.length
	let balanceSettings = {
		changed: false,
		fitness: calculateFitness({ mixNutrients, requirementSet, nutrientSettings })
	}
	while(i--) {
		// "from" nutrient
		const ingredient = ingredients[i]
		const { selected, min=0, max=100 } = ingredientsSettings[ingredient] || {}
		const ingredientMin = selected ? min : 0
		const ingredientMax = selected ? max : 100
		let j = ingredients.length
		while(j--) {
			if(i !== j) {
				const iterationSettings = {
					delta: 100 * GRANULARITY
				}
				// "too" nutrient
				let reverse = false
				const toIngredient = ingredients[j]

				const toIngredientSettings = ingredientsSettings[toIngredient]
				const toIngredientMin = toIngredientSettings?.min || 0
				const toIngredientMax = toIngredientSettings?.max || 100
				const balanceTwoIngredients = () => {
					const fromIsAboveMinWithDeltaRemoval = proportionsCopy[ingredient] - iterationSettings.delta >= ingredientMin * GRANULARITY
					const fromIsBelowMaxWithDeltaAddition = proportionsCopy[ingredient] + iterationSettings.delta <= ingredientMax * GRANULARITY
					const toIsBelowMaxWithDeltaAddition = proportionsCopy[toIngredient] + iterationSettings.delta <= toIngredientMax * GRANULARITY
					const toIsAboveMinWithDeltaRemove = proportionsCopy[toIngredient] - iterationSettings.delta >= toIngredientMin * GRANULARITY
					const isValidForwardSwap = !reverse && fromIsAboveMinWithDeltaRemoval && toIsBelowMaxWithDeltaAddition
					const isValidReverseSwap = reverse && fromIsBelowMaxWithDeltaAddition && toIsAboveMinWithDeltaRemove
					const isValidSwap = (
						isValidForwardSwap
						|| isValidReverseSwap
					)
					if(isValidSwap) {
						swapIngredientProportion({
							proportions: proportionsCopy, 
							ingredient: reverse ? toIngredient : ingredient, 
							toIngredient: reverse ? ingredient : toIngredient, 
							balanceSettings, iterationSettings,
							dispatch, 
							requirementSet,
							nutrientSettings
						})
					} else {
						iterationSettings.delta /= 10
					}

					if(iterationSettings.delta >= 1) {
						delay(0).then(() => balanceTwoIngredients())
					} else if(!reverse) {
						iterationSettings.delta = 100 * GRANULARITY
						reverse = true
						delay(0).then(() => balanceTwoIngredients())
					} else {
						resolve([
							iterationSettings.fitness, proportionsCopy, 
							mixNutrients, iterationSettings.changed
						])
					}
				}
				balanceTwoIngredients()
			}
		}
	}
})

const balanceLoop = async ({ 
	dispatch, proportions, settings,
	availableIngredients, requirementSet, 
	ingredientsSettings, nutrientSettings,
	iterations=2 
}) => {
	const [newFitness, newProportions, mixNutrients, changed] = await balanceIteration({ 
		dispatch, proportions, requirementSet, 
		settings, ingredientsSettings, nutrientSettings
	})
	if(changed || iterations) {
		return await balanceLoop({ 
			dispatch, settings, proportions: newProportions, 
			availableIngredients, requirementSet, 
			ingredientsSettings, nutrientSettings,
			iterations: changed ? 2 : iterations-1 
		})
	} else {
		return [newFitness, newProportions, mixNutrients, settings]
	}
}

const initIngredientProportions = (selectedIngredeients, ingredientsSettings, availableIngredients) => {
	const effectiveIngredients = selectedIngredeients && selectedIngredeients.length ? selectedIngredeients : availableIngredients
	
	let total = 100 * GRANULARITY

	const initProportions = {}

	effectiveIngredients.forEach(ingredient => {
		const ingredientMin = ingredientsSettings[ingredient]?.min || 0
		const granularMin = ingredientMin * GRANULARITY
		initProportions[ingredient] = 0
		if(granularMin) {
			initProportions[ingredient] += granularMin
			total -= granularMin
		} 
	})

	if(total) {
		effectiveIngredients.forEach(ingredient => {
				const ingredientMax = ingredientsSettings[ingredient]?.max || 100
				const granularMax = (ingredientMax || 100) * GRANULARITY
				if(total) {
					if(total + initProportions[ingredient] <= granularMax) {
						initProportions[ingredient] += total
						total = 0
					} else {
						const delta = granularMax - initProportions[ingredient]
						initProportions[ingredient] += delta
						total -= delta
					}
				}
		})
	}

	return initProportions
}

const balance = async ({ props, dispatch, availableIngredients }) => {
	dispatch([
		{ type: 'START_RUNNING' },
		{ type: 'COLLAPSE_GENERAL_SETTINGS' },
		{ type: 'COLLAPSE_NUTRIENT_SETTINGS' },
		{ type: 'COLLAPSE_INGREDIENT_SELECTOR' },
		{ type: 'EXPAND_BALANCE_GRAPH' }
	])
	
	const requirementSet = getRequirementSet(props.settings)

	const selectedIngredients = Object.keys(props.ingredientsSettings).filter(
		ingredient => props.ingredientsSettings[ingredient].selected
	)
	const ingredientsSettings = copy(props.ingredientsSettings)

	const proportions = initIngredientProportions(
		selectedIngredients, 
		ingredientsSettings,
		availableIngredients
	)

	const nutrientSettings = copy(props.nutrientSettings)
	const settings = copy(props.settings)

	await balanceLoop({
		dispatch, requirementSet, 
		proportions, settings, nutrientSettings,
		availableIngredients, ingredientsSettings
	})

	dispatch([
		{
			type: 'SET_MIX_SETTINGS',
			settings,
			ingredientsSettings,
			nutrientSettings
		},
		{ type: 'DONE_RUNNING' },
		{ type: 'RECIPE_UNSAVED' }
	])
}

export default balance