import React from 'react'

const List = ({ rows }) => {
	return <div className="List">
		{rows.map(({ onClick, content, flex }) => <div 
			onClick={onClick} 
			className={`row${onClick ? ' interactive' : ''}${flex ? ' flex' : ''}`}
		>{content}</div>)}
	</div>
}

export default List