import React from 'react'

const CollapsibleSection = ({ 
	title, content, doUpdate=false,
	defaultCollapsed=false, smallTitle=false,
	controlledCollapsed, setControlledCollapse=()=>{}
}) => {
	const [collapsed, setCollapsed] = React.useState(defaultCollapsed)
	const isControlled = controlledCollapsed !== undefined && setControlledCollapse
	const useCollapsed = isControlled ? controlledCollapsed : collapsed
	
	const [height, setHeight] = React.useState(0)
	const contentGutsRef = React.useRef()

	const contentHeight = contentGutsRef?.current?.clientHeight
	
	React.useEffect(() => {
		setHeight(contentGutsRef.current.clientHeight || 0)
	}, [contentHeight, doUpdate])
	
	React.useEffect(() => {
		const handleResize = e => {
			setHeight(contentGutsRef.current.clientHeight || 0)
		}
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])
	return (
		<div className="CollapsibleSection">
			<div className="interactive flex" onClick={e => isControlled ? setControlledCollapse(!useCollapsed) : setCollapsed(!useCollapsed)}>
				{smallTitle ? title : <h3>{title}</h3>}
				&nbsp;
				<span className={`collapse_caret${useCollapsed ? ' rotate_180_translate_y_25' : ''}`}>^</span>
			</div>
			<div 
				style={{ 
					height: useCollapsed ? '0px' : (height ? `${height + 32}px` : 'auto')
				}} 
				className={`content${useCollapsed ? ' collapsed' : ''}`}
			><div ref={contentGutsRef}>{content}</div></div>
		</div>
	)
}

export default CollapsibleSection