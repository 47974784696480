import React from 'react'

const Tabs = ({ options, view, setView, disabled=[] }) => {
	return <div className="Tabs flex">{options.map(opt => {
		const isDisabled = disabled.includes(opt)
		return <span 
			className={`tab flex_grow_1 ${
				opt === view ? 'selected' 
				: isDisabled ? ''
				: 'interactive'
			}${
				isDisabled
				? ' disabled'
				: ''
			}`}
			onClick={(isDisabled || opt === view) ? null : e => setView(opt)}
		><span className="tab_text_wrapper">{opt}</span></span>
	})}</div>
}

export default Tabs