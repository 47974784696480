import React, { useState } from 'react'

import CollapsibleSection from '../../components/CollapsibleSection/CollapsibleSection.jsx'
import NutrientSettings from '../NutrientSettings/NutrientSettings.jsx'

const AdvancedSettings = ({ props, dispatch }) => {
	const [warningCollapsed, setWarningCollapsed] = useState(false)

	return <div className="AdvancedSettings">
		<CollapsibleSection 
			title="Advanced Settings"
			defaultCollapsed={true}
			doUpdate={warningCollapsed}
			content={
				<NutrientSettings props={props} dispatch={dispatch} warningCollapsed={warningCollapsed} setWarningCollapsed={setWarningCollapsed}/>
			}
		/>
	</div>
}

export default AdvancedSettings