import React, { useState } from 'react'
import ingredients from '../../consts/Ingredients/meta.js'
import { GRANULARITY } from '../../consts.js'
import { stringToColour } from '../../utils.js'
import balance from '../../balance.js'

import Spinner from '../../components/Spinner/Spinner.jsx'

const BalanceGraphBar = ({ ingredient, proportions, selectedIngredients, index }) => {
	const [hover, setHover] = useState(false)
	const title = ingredients[ingredient][0]
	return (
		<span 
			style={{
				height: `${proportions[ingredient] / GRANULARITY}%`,
				backgroundColor: stringToColour(`${ingredient}_${ingredient}`)
			}}
			className={`balance_graph_bar${hover ? ' hover' : ''}`}
			title={title}
			key={`graph_bar_${ingredient}`} 
			onMouseEnter={e => setHover(true)} 
			onMouseLeave={e => setHover(false)}
		>
			<span className="proportion">{proportions[ingredient] / GRANULARITY}</span>
			<span 
				className="title" 
				style={{zIndex: 
					hover
					? selectedIngredients.length + 1 
					: selectedIngredients.length - index
				}} 
			>{title}</span>
		</span>
	)
}

const BalanceGraph = ({ props, dispatch, availableIngredients }) => {
	const selectedIngredients = Object.keys(props.ingredientsSettings).filter(
		ingredient => props.ingredientsSettings[ingredient].selected
	)
	const useIngredients = (
		selectedIngredients.length
		? selectedIngredients
		: availableIngredients
	)

	const proportions = props.proportions

	return <div className="BalanceGraph">
		<div className="balance_graph flex">
			<span id="balance_graph_fitness">Fitness: {props.fitness}</span>
			{useIngredients
				.filter(ingredient => proportions[ingredient])
				.sort((a, b) => proportions[b] - proportions[a])
				.map((ingredient, index) => <BalanceGraphBar {...{ 
					ingredient, proportions,
					selectedIngredients: useIngredients, index 
				}} key={`ingredient_${ingredient}_proportion_bar`}/>)
			}
		</div>
		<button 
			className={props.running ? "disabled" : "interactive" }
			onClick={
				props.running
				? null 
				: e => balance({ props, dispatch, availableIngredients })
			}
		>{props.running ? <Spinner/> : 'Run'}</button>
	</div>
}

export default BalanceGraph