import React, { useEffect } from 'react'
import localForage from "localforage"

import About from '../About/About.jsx'
import Recipes from '../Recipes/Recipes.jsx'
import Balancer from '../Balancer/Balancer.jsx'
import Login from '../Login/Login.jsx'

import { createReducer, defaultNutrientSettings } from '../../utils.js'
import actions from './actions.js'

const { useReducer } = React

const reducer = createReducer(actions)

const fillNutrientDefaultsWithLocal = (nutrientSettings) => {
	return Object.keys(nutrientSettings).reduce((finalSettings, nutrient) => {
		const localNutrientSetting = localStorage.getItem(`nutrient_${nutrient}`)
		const [weight, ignoreExcess] = localNutrientSetting?.split(',') || []
		const nutrientSetting = {
			weight: nutrientSettings[nutrient]?.weight || 1,
			ignoreExcess: nutrientSettings[nutrient]?.ignoreExcess || false
		}
		if(weight && +weight >= 0) {
			nutrientSetting.weight = +weight
		}
		if(ignoreExcess) {
			nutrientSetting.ignoreExcess = (
				ignoreExcess === 'true' ? true
				: ignoreExcess === 'false' ? false
				: nutrientSetting.ignoreExcess
			)
		}
		finalSettings[nutrient] = nutrientSetting
		return finalSettings
	}, {})
}

const validFatnesses = {
	underweight: true,
	slim: true,
	ideal: true,
	overwight: true,
	obese: true
}

const initState = () => {
	const age = localStorage.getItem('age')
	const isValidAge = age && +age >= 0

	const inEarlyGrowth = localStorage.getItem('inEarlyGrowth')
	const isValidEarlyGrowth = inEarlyGrowth && +inEarlyGrowth > 0

	const currentWeight = localStorage.getItem('currentWeight')
	const isValidCurrentWeight = currentWeight && +currentWeight > 0

	const adultWeight = localStorage.getItem('adultWeight')
	const isValidAdultWeight = adultWeight && +adultWeight > currentWeight

	const isLactating = localStorage.getItem('isLactating')
	const isValidLactatingCheck = isLactating === 'true'

	const isPregnant = localStorage.getItem('isPregnant')
	const isValidPregnantCheck = isPregnant === 'true'

	const fatness = localStorage.getItem('fitness')
	const validFatness =  validFatnesses[fatness]

	const weeksPreggo = localStorage.getItem('weeksPregnant')
	const isValidWeeksPreggers = weeksPreggo && +weeksPreggo >= 0

	const weeksLactating = localStorage.getItem('weeksLactating')
	const isValidWeeksLactating = weeksLactating && +weeksLactating >= 0

	const numberOfPuppies = localStorage.getItem('numberOfPuppies')
	const isValidNumberOfPuppies = numberOfPuppies && +numberOfPuppies > 0

	const hoursHighImpact = localStorage.getItem('hoursRunning')
	const isValidHighImpact = hoursHighImpact && +hoursHighImpact >= 0

	const hoursLowImpact = localStorage.getItem('hoursWalking')
	const isValidLowImpact = hoursLowImpact && +hoursLowImpact >= 0

	let storedIngredients = localStorage.getItem('ingredients') || ''
	let storedIngredientsSettings = {}
	storedIngredients.split(',').forEach(ingredient => {
		if(!ingredient) {
			return
		}
		const storedIngredientSettings = localStorage.getItem(`ingredient_${ingredient}`) || ''
		const [selected, min, max] = storedIngredientSettings.split(',')
		storedIngredientsSettings[ingredient] = { 
			selected: selected === 'true', 
			min: +min, 
			max: +max 
		}
	})

	const storedName = localStorage.getItem('dogName') || ''

	return ({
		// these booleans indicate required inputs validity
		errors: {
			name: null,
			age: null,
			weight: null,
			adultWeight: null,
			weeksInGestation: null,
			weeksInLactation: null,
			numberOfPuppies: null
			// name: null
		},
		dogs: [],
		recipeName: '',
		recipes: [],
		recipeSaved: -1,
		selectedRecipe: -1,
		selectedDog: undefined,
		mixIngredients: {},
		mixNutrientSettings: {},
		settings: {
			name: storedName,

			years: isValidAge ? +age : undefined,
			inEarlyGrowth: isValidEarlyGrowth || undefined,
			currentWeight: isValidCurrentWeight ? +currentWeight : undefined,
			adultWeight: isValidAdultWeight ? +adultWeight : undefined,
			isLactating: isValidLactatingCheck || false,
			isPregnant: isValidPregnantCheck || false,
			fatness: validFatness ? fatness : 'ideal',

			weeksInGestation: isValidWeeksPreggers ? +weeksPreggo : undefined,
			weeksInLactation: isValidWeeksLactating ? +weeksLactating : undefined,
			numberOfPuppies: isValidNumberOfPuppies ? +numberOfPuppies : undefined,

			hoursLowImpactActivity: isValidHighImpact ? +hoursHighImpact : undefined,
			hoursHighImpactActivity: isValidLowImpact ? +hoursLowImpact : undefined
		},
		// views: balancer, about
		view: 'balancer',
		balancerView: 'settings',
		running: false,
		loaded: false,
		proportions: {},
		mixSettings: {},
		fitness: 0,
		mer: null,
		ingredientSets: {
			/*{SET_NAME}: string[] // array of ingredient strings*/
		},
		ingredientsSettings: storedIngredientsSettings,
		nutrientSettings: fillNutrientDefaultsWithLocal(
			defaultNutrientSettings()
		),
		
		collapsed: {
			generalSettings: false,
			nutrientSettings: false,
			ingredientsSelector: false,
			balanceGraph: false,
			results: false
		}
	})
}

const Navigation = ({ props, dispatch }) => {
	return <div className="Navigation tabs flex spaceAround">{
		['balancer', 'about', 'recipes'].map(
			tabKey => <div 
				className={`tab flex_grow_1 ${
					tabKey === props.view 
					? 'selected' 
					: 'interactive'
				}`}
				onClick={e => {
					dispatch({
						type: 'NAVIGATE',
						view: tabKey
					})
				}}
			>
				<div className="tab_text_wrapper">{tabKey[0].toUpperCase() + tabKey.slice(1)}</div>
			</div>
		)
	}</div>
}

const App = () => {
	const [props, dispatch] = useReducer(reducer, initState())
	console.log('props: ', props)
	useEffect(() => {
		const storedSelectedDog = localStorage.getItem('selectedDog') || undefined
		localForage.getItem('dogSettings').then(defaultDogSettings => {
			if(defaultDogSettings) {
				const actions = [{
					type: 'SET_DOG_SETTINGS_SETS',
					dogs: defaultDogSettings
				}]
				if(defaultDogSettings.find(dog => dog.name === storedSelectedDog)) {
					actions.push({
						type: 'SELECT_DOG_SETTINGS',
						name: storedSelectedDog
					})
					actions.push({ type: 'UPDATE_MER' })
				}
				dispatch(actions)
			}
		})

		localForage.getItem('ingredientSets').then(defaultIngredientSets => {
			if(defaultIngredientSets) {
				dispatch({
					type: 'SET_INGREDIENT_SETS',
					sets: defaultIngredientSets
				})
			}
		})

		localForage.getItem('recipes').then(savedRecipes => {
			if(savedRecipes) {
				dispatch({
					type: 'SET_RECIPES',
					recipes: savedRecipes
				})
			}
		})
	}, [])

	return <>
		<Navigation props={props} dispatch={dispatch}/>
		<div className="App">{
			props.view === 'about' ? <About props={props} dispatch={dispatch}/>
			: props.view === 'recipes' ? <Recipes props={props} dispatch={dispatch}/>
			: props.view === 'login' ? <Login props={props} dispatch={dispatch}/>
			: <Balancer props={props} dispatch={dispatch}/>
		}</div>
	</>
}

export default App
