import React from 'react'
import ingredients from '../../consts/Ingredients/meta.js'
import { validateDogSettings } from '../../utils.js'
import balance from '../../balance.js'

import BalanceGraph from '../BalanceGraph/BalanceGraph.jsx'
import IngredientsSelector from '../IngredientsSelector/IngredientsSelector.jsx'
import GeneralSettings from '../GeneralSettings/GeneralSettings.jsx'
import Tabs from '../../components/Tabs/Tabs.jsx'
import Recipe from '../Recipe/Recipe.jsx'

const handleGenerateClick = ({ e, props, dispatch, availableIngredients }) => {
	const invalidFieldsErrorDisplayActions = validateDogSettings({ props, dispatch })

	if(!invalidFieldsErrorDisplayActions.length) {
		balance({ props, dispatch, availableIngredients })
	} else {
		dispatch(invalidFieldsErrorDisplayActions)
		document.body.scrollTop = document.documentElement.scrollTop = 0
	}
}

const Balancer = ({ props, dispatch }) => {
	const view = props.balancerView

	const availableIngredients = Object.keys(ingredients).filter(
		ingredient => ingredients[ingredient][2].includes('dog')
	)
	const invalidFieldsErrorDisplayActions = validateDogSettings({ props, dispatch })

	const enabledTabs = ['settings']

	if(!invalidFieldsErrorDisplayActions.length) {
		enabledTabs.push('ingredients')
		if(props.running || props.loaded) {
			enabledTabs.push('balance')

			if(props.loaded && !props.running) {
				enabledTabs.push('results')
			}
		}
	}

	const tabOptions = ['settings', 'ingredients', 'balance', 'results']

	const disabledTabs = tabOptions.filter(opt => !enabledTabs.includes(opt))

	const previousSettingKeys = Object.keys(props.mixSettings)
	const currentSettingsKeys = Object.keys(props.settings)
	const dogSettingsMatch = (
		previousSettingKeys.length === currentSettingsKeys.length
		&& previousSettingKeys.every(
			propKey => props.mixSettings[propKey] === props.settings[propKey]
		)
	)

	const previousRunIngredients = Object.keys(props.mixIngredients || {}).filter(
		ingredient => props.mixIngredients[ingredient].selected
	).sort()
	const selectedIngredients = Object.keys(props.ingredientsSettings || {}).filter(
		ingredient => props.ingredientsSettings[ingredient].selected
	).sort()
	const ingredientsMatch = (
		selectedIngredients.length === previousRunIngredients.length 
		&& previousRunIngredients.every(
			(ing, i) => {
				const previousIngSettings = props.mixIngredients[ing] || {}
				const previousIngMin = previousIngSettings.min || 0
				const previousIngMax = previousIngSettings.max || 100
				const currentIngSettings = props.ingredientsSettings[ing] || {}
				const currentIngMin = currentIngSettings.min || 0
				const currentIngMax = currentIngSettings.max || 100
				return (
					previousRunIngredients[i] === selectedIngredients[i]
					&& previousIngMin === currentIngMin
					&& previousIngMax === currentIngMax
				)
			}
		)
	)

	const currentNutrientKeys = Object.keys(props.nutrientSettings)
	const previousNutrientKeys = Object.keys(props.mixNutrientSettings)
	const nutrientSettingsMatch = (
		currentNutrientKeys.length === previousNutrientKeys.length
		&& currentNutrientKeys.every(
			nutrient => {
				const currentNutrientSettings = props.nutrientSettings[nutrient]
				const currentNutrientWeight = currentNutrientSettings.weight || 1
				const currentNutrientIgnoreExcess = currentNutrientSettings.ignoreExcess || false
				const previousNutrientSettings = props.mixNutrientSettings[nutrient]
				const previousNutrientWeight = previousNutrientSettings.weight || 1
				const previousNutrientIgnoreExcess = previousNutrientSettings.ignoreExcess || false
				return (
					currentNutrientWeight === previousNutrientWeight
					&& currentNutrientIgnoreExcess === previousNutrientIgnoreExcess
				)
			}
		)
	)

	const settingsMatch = (
		dogSettingsMatch
		&& ingredientsMatch
		&& nutrientSettingsMatch
	)

	const nextView = (
		view === 'ingredients' ? 'balance'
		: view === 'balance' ? 'results'
		: 'ingredients'
	)

	const nextDisabled = disabledTabs.includes(nextView)

	return <div className="Balancer">
		<h1>
			Canine Diet Generator
			<span className="small_text align_top">BETA</span>
		</h1>
		<Tabs
			options={tabOptions}
			disabled={disabledTabs}
			view={view}
			setView={view => dispatch({ type: 'SET_BALANCER_VIEW', view })}
		/>
		<div className="content">
			<div className="flex space_between balancer_nav_buttons">
				{
					view === 'settings' 
					? null
					: <button onClick={e => {
						dispatch({
							type: 'SET_BALANCER_VIEW', 
							view: (
								view === 'balance' ? 'ingredients'
								: view === 'results' ? 'balance'
								: 'settings'
							)
						})
					}}>&#8592; Back</button>
				}
				{
					view === 'results' ? null
					: view === 'ingredients' ? <button
						onClick={e => {
							dispatch({ 
								type: 'SET_BALANCER_VIEW', 
								view: nextView
							})
							if(!settingsMatch && !props.running) {
								handleGenerateClick({ props, dispatch, availableIngredients })
							}
						}}
					>{props.running || settingsMatch  ? 'next' : 'Generate recipe'} &#8594;</button>
					: <button 
						className={`margin_left_auto${
							invalidFieldsErrorDisplayActions.length || nextDisabled 
							? ' disabled' 
							: ''
						}`}
						onClick={e => {
							if(invalidFieldsErrorDisplayActions.length) {
								dispatch(invalidFieldsErrorDisplayActions)
							} else {
								if(!nextDisabled) {
									dispatch({ 
										type: 'SET_BALANCER_VIEW', 
										view: nextView
									})
								}
							}
						}}
					>Next &#8594;</button>
				}
			</div>
			{
				view === 'settings' ? <GeneralSettings props={props} dispatch={dispatch}/>
				: view === 'ingredients' ? <IngredientsSelector 
					dispatch={dispatch}  
					props={props} 
					availableIngredients={availableIngredients}
				/>
				: view === 'balance' ? <BalanceGraph 
					props={props} 
					dispatch={dispatch} 
					availableIngredients={availableIngredients}
				/>
				: view === 'results' ? <Recipe 
					recipe={
						props.recipeSaved >= 0
						? props.recipes[props.recipeSaved]
						: {
							ingredients: props.proportions,
							settings: props.mixSettings,
							title: props.recipeName,
							dog: props.selectedDog,
							fitness: props.fitness,
							nutrientSettings: props.mixNutrientSettings,
							ingredientSettings: props.mixIngredients
						}
					}
					dispatch={dispatch}
					index={props.recipeSaved}
				/>
				: null
			}
		</div>
	</div>
}

export default Balancer