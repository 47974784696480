import requirements from './consts/Requirements/meta.js'
import ingredients from './consts/Ingredients/meta.js'
import { GRANULARITY } from './consts.js'

const createReducer = actions => (
    (state, action) => {
        if(Array.isArray(action)) {
            let i = 0
            while(i < action.length) {
                console.log(action[i])
                if(actions[action[i].type]) {
                    state = actions[action[i].type](state, action[i])
                } else {
                    console.warn(`UNKNOWN ACTION TYPE: ${action[i].type}`)
                }
                
                i++
            }
            return {...state}
        } else if(actions[action.type]) {
            console.log(action)
            return {...actions[action.type](state, action)} 
        } else {
            console.warn(`UNKNOWN ACTION TYPE: ${action.type}`)
            return state
        }
    }
)

const shuffle = (array) => {
    const randomValues = Array.from(window.crypto.getRandomValues(new Uint32Array(array.length)))
    const valuesMap = {}
    randomValues.forEach((val, i) => valuesMap[val] = array[i])
    const sortedValues = randomValues.sort()
    return sortedValues.map(val => valuesMap[val])
}

var stringToColour = function(str) {
  var hash = 0;
  let i;
  for (i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}

const delay = (t, v) =>  new Promise((resolve) => { 
   setTimeout(resolve.bind(null, v), t)
})

const lbsToKgs = lbs => lbs / 2.205
const kgsToLbs = kgs => kgs * 2.205

const weeksInLactationFactor = [0.75, 0.95, 1.1, 1.2]

const calcLactationMER = (weeksInLactation, numberOfPuppies) => {
    const puppyMER = (
        numberOfPuppies > 4
        ? 96 + (12 * numberOfPuppies)
        : 24 * numberOfPuppies
    ) * weeksInLactationFactor[weeksInLactation]

    return 145 + puppyMER ** 0.75
}

const calcGestationMER = (weeksInGestation) => {
    return 132 + (
        weeksInGestation > 4 
        ? 26 ** 0.75
        : 0
    )
}

const calculateMER = ({
    inLactation,
    weeksInLactation,
    numberOfPuppies,
    inGestation,
    weeksInGestation,
    hoursLowImpactActivity,
    hoursHighImpactActivity,
    years,
    fatness,
    breed,
    weight,
    adultWeight
}) => {
    let mer = (
        inLactation ? calcLactationMER(weeksInLactation, numberOfPuppies)
        : inGestation ? calcGestationMER(weeksInGestation)
        : 95
    )
    
    if(years) {
        if(fatness) {
            if(fatness === 'underweight') {
                mer += mer * 0.10
            } else if(fatness === 'slim') {
                mer += mer * 0.05
            } else if(fatness === 'overwight') {
                mer -= mer * 0.05
            } else if(fatness === 'obese') {
                mer -= mer * 0.10
            }
        }
        if(years < 3) {
            mer += mer * 0.10
        } else if(years > 7) {
            mer -= mer * 0.10
        }
    } else {
        const proportionGrown = weight / adultWeight
        if(proportionGrown <= 0.5) {
            mer += mer * 0.50
        } else if(proportionGrown <= 0.8) {
            mer += mer * 0.30
        } else if(proportionGrown < 1) {
            mer += mer * 0.10
        } 

    } 
    if(breed) {
        if(breed === 'great_dane') {
            mer += mer * 0.30
        } else if(breed === 'newfoundland') {
            mer -= mer * 0.10
        } else if(breed === 'terrier') {
            mer += mer * 0.20
        }
    }

    if(hoursLowImpactActivity) {
        mer += hoursLowImpactActivity * 5
    }
    if(hoursHighImpactActivity) {
        mer += hoursHighImpactActivity * 10
    }

    return mer
}

const printElement = (id, title) => {
    const printDivCSS = `<style>
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }

        input[type=number] {
            -moz-appearance:textfield; /* Firefox */
        }

        body {
            overscroll-behavior-y: none;
            margin: 0px;
        }

        h1 {
            margin-block-start: 0px;
            margin-block-end: 0px;
        }

        html {
            --background-primary: #ffffff;
            --text-color-general: #000000;
            --border-color-primary: #000000;
            --info-color: #000000;
            --warning-color: #000000;
            --interactive-color: #000000;
            --background-color-secondary: #ffffff;
            
            font-family: Georgia, serif;
            background-color: var(--background-primary);
        }

        button,
        .icon, 
        .name_editor,
        .Tooltip,
        .recipe_actions {
            display: none;
        }

        input[type=checkbox] {
            cursor: pointer;
        }

        #root {
            overflow: hidden;
        }

        #root,
        .App {
            height: 100%;
        }

        .App {
            padding: 12px;
            margin-top: 12px;
        }

        .App .content {

        }

        .App .Tabs {
            margin-top: 12px;
        }

        .IngredientsSelector > *+*,
        .BalanceGraph > *+*,
        .RecipeList > *+*,
        .Recipe > * {
            margin-top: 12px;
        }

        .Recipe {
            background-color: var(--background-color-secondary);
            margin-top: 16px;
            
        }
        .Results .Recipe {
            border-top: 1px solid var(--border-color-primary);
        }
        .Recipes .Recipe {
            border: 1px solid var(--border-color-primary);
            padding: 18px;
        }

        button.disabled {
            background-color: var(--info-color);
        }

        .StartButton button {
            padding: 5px 12px;
            font-size: 15px;
        }

        .BalanceGraph.border {
            padding-bottom: 24px;
        }
        #ingredients_select_lists {
            overflow: auto;
        }

        .select_ingredient_option_title {
            width: calc(100% - 30px);
            overflow: hidden;
            text-overflow: ellipsis;
        }

        #ingredients_select_lists .Select .option {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .results_inputs button,
        .saved_recipe_header button {
            height: fit-content;
        }

        .recipe_metadata h1 {
            padding-bottom: 12px;
        }

        .recipe_metadata h3 {
            padding-bottom: 4px;
        }

        .recipe_metadata > *:not(:first-child),
        .MERPreview > *:not(:first-child),
        .MixAnalytics > *:not(:first-child) {
            padding-top: 6px;

        }

        .add_ingredient_set_row {
            width: 100%;
        }

        .add_ingredient_set_row input {
            width: calc(100% - 18px);
        }

        .add_ingredient_set_icon {
            position: absolute;
            right: 8px;
        }

        #ingredients_select_lists .Select {
            max-width: 50%;
            margin-left: auto;
            height: fit-content;
        }

        #ingredients_select_lists,
        .GeneralSettings > .flex {
            padding: 5px;
        }

        #ingredients_select_lists > .flex_grow_1,
        .GeneralSettings > .flex > * {
            margin: 5px;
            min-width: 320px;
        }

        .AdvancedSettings {
            width: 100%;
            padding-top: 16px;
            border-top: 1px solid var(--border-color-primary);
        }

        .rotate_180 {
            transform: rotate(180deg) translateY(25%);
        }
        .flex {
            display: flex;
        }
        .space_between {
            justify-content: space-between;
        }

        .center_text {
            text-align: center;
        }

        .rotate_45 {
            transform: rotate(45deg);
        }

        .place_self_center {
            place-self: center;
        }

        .flex_wrap {
            flex-wrap: wrap;
        }

        .margin_left_auto {
            margin-left: auto;
        }

        .justify_right {
            justify-content: flex-end;
        }

        .nowrap {
            white-space: nowrap;
        }

        .hide_overflow {
            overflow: hidden;
        }

        .hidden {
            visibility: hidden;
        }

        .interactive,
        .interactive label,
        .interactive_text {
            cursor: pointer;
            user-select: none;
        }
        .interactive:hover {
            font-weight: bold;
        }
        table .interactive:hover {
            font-weight: normal;
        }
        .hover_bold:hover {
            font-weight: bold;
        }
        span {
            display: inline-block;
        }
        label {
            display: block;
            font-size: 15px;
            margin-bottom: 6px;
            width: fit-content;
            font-weight: normal;
        }
        .spaceBetween {
            justify-content: space-between;
        }
        .spaceAround {
            justify-content: space-around;
        }
        .justify_center {
            justify-content: center;
        }

        .collapse_caret {
            height: min-content;
            font-size: 20px;
        }

        #settings_sections > * {
            height: fit-content;
        }

        .border {
            border: 1px solid var(--border-color-primary);
            padding: 12px;
            border-radius: 4px;
        }
        .lightSteelBackground {
            background-color: var(--background-color-secondary);
        }

        .YearsInput,
        .CurrentWeightInput,
        .AdultWeightInput,
        .BasicSettings  > *+*,
        .BitchSettings > *+*,
        .NutrientSettings .scoll_container > *,
        .ActivitySettings > *+* {
            padding: 4px 12px;

        }


        h2, h3 {
            margin-block-start: 0px;
            margin-block-end: 2px;
        }

        .GeneralSettings .selectors > *:not(:last-child) {
            padding-bottom: 12px;
            margin-bottom: 12px;
            border-bottom: 1px solid var(--border-color-primary);
        }

        .SaveDogSettingsButton {
            margin-right: 20px;
        }

        .SaveDogSettingsButton button {
            height: fit-content;
            display: block;
        }

        .nutritional_label > div > span:first-child {
            width: 40%;
        }

        .balance_graph {
            position: relative;
            height: 320px;
            justify-content: flex-start;
            align-items: flex-end;
            margin-bottom: 18px;
            border-left: 2px solid var(--border-color-primary);
            border-bottom: 2px solid var(--border-color-primary);
        }


        #balance_graph_fitness {
            position: absolute;
            top: 0px;
            right: 12px;
        }
        .balance_graph_bar {
            position: relative;
            flex-grow: 1;
            margin: 0px 6px;
            max-width: 40px;
        }
        table {
            width: 100%;
            border-collapse: collapse;
        }

        .unused_ingredient {
            display: none;
        }

        .RecipeList table {
            background-color: var(--background-color-secondary);
        }

        .flex_grow_1 {
            flex-grow: 1;
            flex-shrink: 0;
        }

        .final_proportions,
        .nutritional_label {
            padding-top: 10px;
        }

        .final_proportions {
            padding-right: 10px;
        }

        .nutritional_label {
            padding-left: 10px;
            border-left: 1px solid var(--border-color-primary);
        }

        .final_proportions > h3,
        .nutritional_label > h3 {
            margin: 0;
        }

        .final_proportions > div,
        .nutritional_label > div {
            padding: 6px;
            border-bottom: 1px solid var(--border-color-primary);
        }

        .balance_graph_bar .proportion {
            position: absolute;
            top: calc(100% + 4px);
            left: 50%;
            transform: translateX(-50%);
        }

        .balance_graph_bar.hover .title {
            border: 2px solid var(--border-color-primary);
            z-index: 1000;
        }

        .balance_graph_bar .title {
            position: absolute;
            bottom: calc(100% + 4px);
            background-color: var(--background-primary);
            padding: 2px 6px;
            border-radius: 2px;
            border: 1px solid var(--border-color-primary);
            white-space: nowrap;
        }

        .red_text {
            color: var(--warning-color);
        }
        .interactive_text,
        a:-webkit-any-link {
            color: var(--interactive-color);
        }

        label[for="available_ingredients"],
        label[for="selected_ingredients"] {
            font-size: 18px;
        }

        .scoll_container {
            max-height: 50vh;
            overflow: auto;
        }

        .NutrientSettings {
            height: fit-content;
            border-top: 1px solid var(--border-color-primary);
            padding-top: 12px;
            margin-top: 12px;
        }

        .balancer_nav_buttons {
            margin-bottom: 12px;
            padding-bottom: 12px;
            /*border-bottom: 1px solid var(--border-color-primary);*/
        }

        #ingredients_select_lists .SelectedIngredients {
            padding-right: 13px;
            margin-right: 8px;
            border-right: 1px solid var(--border-color-primary);
        }

        .NutrientSettings .title {
            padding-right: 6px;
        }

        .NutrientSettings td:not(:last-child) {
            padding-right: 12px;
        }

        .NutrientSettings table {
            width: 100%;
            max-height: 50vh;
            overflow: auto;
        }

        .NutrientSettings table tr > * {
            text-align: left;
            /*display: inline-table;*/
            border-bottom: 1px solid var(--border-color-primary);
            margin-bottom: 6px;
            padding-bottom: 6px;
        }
        .NutrientSettings table tr .justify_center {
            justify-content: center;
        }
        .NutrientSettings table tr .flex {
            display: flex;
        }

        .NutrientSettings table tr > *:first-child {
            width: 169px;
        }
        .NutrientSettings table tr > *:nth-child(2) {
            width: 64px
        }
        .NutrientSettings table tr > *:last-child {
            width: 112px;
        }

        .BasicSettings *+* {
            vertical-align: top;
        }

        .align_top {
            vertical-align: top;
        }

        .About .Tabs {
            margin-top: 12px;
        }

        .App > .content,
        .About .content {
            padding: 12px;
            border: 1px solid var(--border-color-primary);
            border-top: 0px;
            background-color: var(--background-color-secondary);
        }

        #penalize_excess_container {
            padding: 16px 0px;
            width: fit-content;
        }

        #selected_ingredients .row .ingredient_settings > *+* {
            margin-left: 4px;
        }

        #selected_ingredients .row .ingredient_settings label,
        #settings_sections label {
            margin-bottom: 0px;
            font-size: 12px;
        }

        table thead td {
            font-weight: bold;
            white-space: nowrap;
        }

        input[type="tel"] {
            width: 46px;
        }

        #available_ingredients,
        #selected_ingredients {
            overflow-y: scroll;
        }

        #available_ingredients > *,
        #selected_ingredients > * {
            padding: 6px;
            border-bottom: 1px solid var(--border-color-primary);
        }

        #available_ingredients > *:first-child,
        #selected_ingredients > *:first-child {
            border-top: 1px solid var(--border-color-primary);
        }

        .DogSelect {
            width: 200px;
        }

        .dog_option {
            width: calc(100% - 18px);
        }

        .dog_set_controls {
            max-width: 70%;
            white-space: nowrap;
        }

        .DogSelect *,
        .Select * {
            font-size: 15px;
            font-weight: normal;
        }

        .Select {
            position: relative;
            width: calc(100% - 24px);
            border: 1px solid var(--border-color-primary);
            padding: 0px 8px;
            border-radius: 2px;
        }

        .Select .option {
            padding: 8px 12px;
        }
        .Select .option:not(:first-child) {
            border-top: 1px solid var(--border-color-primary);
        }

        .Select .title > .collapse_caret {
            position: absolute;
            right: 6px;
        }

        .Select .title {
            /*cursor: pointer;*/
            width: calc(100% - 12px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .selectDrodown {
            transition: all 0.5s ease;
            border: 1px solid var(--border-color-primary);
            overflow: hidden;
            position: absolute;
            top: 100%;
            right: -1px;
            width: 100%;
            background-color: var(--background-primary);
            z-index: 10;
        }

        .dog_option_text {
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 14px;
        }

        .dog_option_delete_icon {
            position: absolute;
            right: 12px;
        }

        .proportions_and_nutrition {
            margin-top: 16px;
            border-top: 1px solid var(--border-color-primary);
        }

        .Tooltip {
            position: relative;
        }

        .invalid_input *,
        .invalid_input .Tooltip .icon {
            color: var(--warning-color);
            border-color: var(--warning-color);
        }
        .invalid_input .Tooltip .tip {
            color: var(--text-color-general);
        }

        .Tooltip .icon {
            border: 1px solid var(--border-color-primary);
            border-radius: 50%;
            height: 12px;
            width: 12px;
            font-size: 11px;
            text-align: center;
            transform: scale(0.6);
            vertical-align: top;
        }

        .Tooltip .tip {
            width: max-content;
            max-width: 50vw;
            position: absolute;
            top: 14px;
            left: -2px;
            background-color: var(--background-primary);
            border: 1px solid var(--border-color-primary);
            display: none;
            padding: 12px;
        }

        .Tooltip:hover .tip {
            display: unset;
            z-index: 1;
        }

        .CollapsibleSection {
            height: fit-content;

        }
        .Results {
            background-color: var(--background-color-secondary);
            height: fit-content;
            padding: 16px;
        }

        .CollapsibleSection .content {
            overflow-y: hidden;
            transition: all 0.5s ease;
        }

        .CollapsibleSection .content * input, 
        .CollapsibleSection .content * .icon {
            line-height: 1em;
        }

        .small_text {
            font-size: 10px;
        }

        #dog_name_input {
            width: 100px
        }

        .Navigation {
            height: 3em;
            border-bottom: 1px solid var(--border-color-primary);
        }

        .Tabs .tab,
        .tabs .tab {
            text-align: center;
        }

        .Tabs .tab.disabled .tab_text_wrapper {
            background-color: var(--info-color);
        }

        .Tabs .tab.selected .tab_text_wrapper,
        .tabs .tab.selected .tab_text_wrapper {
            background: radial-gradient(ellipse at top, #4a42b5 -1%, var(--background-color-secondary) 100%);
        }

        .tab_text_wrapper {
            display: flex;
            justify-content: center;
            height: 100%;
            flex-direction: column;
            font-size: 18px;
            background-color: var(--background-color-secondary);
        }

        .Tabs *:not(:first-child),
        .tabs *:not(:first-child) {
            border-left: 1px solid var(--border-color-primary);
        }

        .RecipeList table td {
            border: 1px solid var(--border-color-primary);
            padding: 6px;
        }

        .Tabs {
            height: 2em;
            border: 1px solid var(--border-color-primary);
        }
    </style>`
    const printEl = document.getElementById(id)
    const printHtml =  `${printDivCSS}${title ? `<h1>${title}</h1>` : ''}<div>${printEl.innerHTML}</div>`
    window.frames["print_frame"].document.body.innerHTML = printHtml
    window.frames["print_frame"].window.focus()
    window.frames["print_frame"].window.print()
}

const printResults = (title) => {
    console.log('PRINT WITH TITLE: ', title)
    printElement('proportions_and_nutrition', title)
}

const sigFigs = (string, figs) => {
    return +(`${string}`.split('.').map(
        (number, i) => !i ? number : number.slice(0, 2)
    ).join('.'))
}

const getSelectedRequirementKey = (settings) => {
    return (
        settings.years > 0 ? 'adult'
        : settings.inEarlyGrowth ? 'puppy_early'
        : lbsToKgs(settings.adultWeight) >= 15 ? 'puppy_late_large'
        : 'puppy_late'
    )
}

const getRequirementSet = (settings) => {
    const requirementKey = getSelectedRequirementKey(settings)
    if(requirementKey === 'adult') {
        const mer = calculateMER(settings)
        const { adult_MER_95, adult_MER_110 } = requirements
        const adjusted_adult = Object.keys(adult_MER_95[1]).reduce((adjustedNutrients, nutrient) => {
            adjustedNutrients[nutrient] = [...adult_MER_95[1][nutrient]]

            const nutrient95 = +adult_MER_95[1][nutrient][0]
            const nutrient110 = +adult_MER_110[1][nutrient][0]
            const rise = nutrient95 - nutrient110
            const run = 95 - 110
            const m = rise / run
            const b = nutrient95 - (m * 95)

            const adjustedNutrient = (mer * m) + b 
            adjustedNutrients[nutrient][0] = adjustedNutrient
            return adjustedNutrients
        }, {})
        return ['adult', adjusted_adult, adult_MER_95[2]]
    } else {
        return requirements[requirementKey]
    }
}

const calculateMixNutrients100Grams = (proportions) => {
    return Object.keys(proportions).reduce((mixNutrients, ingredient) => {
        const ingredientNutrition = ingredients[ingredient][1]
        const ingredientProportion = proportions[ingredient] / (GRANULARITY * 100)

        Object.keys(ingredientNutrition).forEach(ingredientNutrient => {
            const ingredientNutrientAmount = ingredientNutrition[ingredientNutrient]
            const proportionalNutrientAmount = ingredientProportion * ingredientNutrientAmount

            mixNutrients[ingredientNutrient] = (mixNutrients[ingredientNutrient] || 0) + proportionalNutrientAmount
        })

        return mixNutrients
    }, {})
}

const calculateMixNutrients = (proportions) => {
    const nutrients = calculateMixNutrients100Grams(proportions)

    const kcalPer100G = nutrients.Calories

    // if the nutrient mix has calories
    if(kcalPer100G && kcalPer100G > 0) {
        // calculate the proportion of 1000 kcals there are per 100 grams
        const multiplier = 1000 / kcalPer100G
        // multiply every value in the mix by the proportion
        Object.keys(nutrients).forEach(nutrient => {
            nutrients[nutrient] *= multiplier
        })
    }

    nutrients['Ca / P'] = nutrients['Calcium Ca'] / nutrients['Phosphorus P']
    nutrients['kcal / g'] = kcalPer100G / 100

    return nutrients
}

const defaultNutrientSettings = () => ({
    "kcal / g": {ignoreExcess: true, weight: 5},
    Arginine: {ignoreExcess: true},
    "Ca / P": {ignoreExcess: false, weight: 5},
    "Calcium Ca": {ignoreExcess: false},
    Carbohydrate: {ignoreExcess: false, weight: 1},
    Choline: {ignoreExcess: true},
    "Copper Cu": {ignoreExcess: false},
    Fat: {weight: 5, ignoreExcess: false},
    Folate: {ignoreExcess: true},
    Histidine: {ignoreExcess: true},
    "Iron Fe": {ignoreExcess: true},
    Isoleucine: {ignoreExcess: true},
    Leucine: {ignoreExcess: true},
    Lysine: {ignoreExcess: true},
    "Magnesium Mg": {ignoreExcess: true},
    "Manganese Mn": {ignoreExcess: true},
    Methionine: {ignoreExcess: true},
    "Methionine + Cysteine": {ignoreExcess: true},
    Niacin: {ignoreExcess: true},
    "Pantothenic acid": {ignoreExcess: true},
    Phenylalanine: {ignoreExcess: true},
    "Phenylalanine + Tyrosine": {ignoreExcess: true},
    "Phosphorus P": {ignoreExcess: false},
    "Potassium K": {ignoreExcess: true},
    Protein: {ignoreExcess: true, weight: 20},
    Riboflavin: {ignoreExcess: true},
    "Selenium Se": {ignoreExcess: true},
    "Sodium Na": {ignoreExcess: false},
    Sugars: {ignoreExcess: false},
    Thiamin: {ignoreExcess: true},
    Threonine: {ignoreExcess: true},
    Tryptophan: {ignoreExcess: true},
    Valine: {ignoreExcess: true},
    "Valine ": {ignoreExcess: true},
    "Vitamin A IU": {ignoreExcess: false, weight: 2},
    "Vitamin B6": {ignoreExcess: false},
    "Vitamin B12": {ignoreExcess: false},
    "Vitamin D": {ignoreExcess: false},
    "Vitamin E": {ignoreExcess: false},
    "Zinc Zn": {ignoreExcess: true},
    "α-linolenic acid n-3 (ALA)": {ignoreExcess: true},
    "γ-linolenic acid n-6 (GLA)": {ignoreExcess: true}
})

const validateDogSettings = ({ props, dispatch }) => {
    const errorActions = []
    const age = props.settings.years
    if(typeof age !== 'number' || age < 0) {
        errorActions.push({
            type: 'SHOW_AGE_ERROR'
        })
    }
    const weight = props.settings.currentWeight
    if(typeof weight !== 'number' || weight <= 0) {
        errorActions.push({
            type: 'SHOW_WEIGHT_ERROR'
        })
    }

    const adultWeight = props.settings.adultWeight
    if(age === 0 && (typeof adultWeight !== 'number' || adultWeight <= weight)) {
        errorActions.push({
            type: 'SHOW_ADULT_WEIGHT_ERROR'
        })
    }

    if(props.settings.isLactating) {
        const validLactationWeeksSetting = (
            typeof props.settings.weeksInLactation === 'number'
            && props.settings.weeksInLactation >= 0
        )
        if(!validLactationWeeksSetting) {
            errorActions.push({
                type: 'SHOW_LACTATION_WEEKS_ERROR'
            })
        }

        const validPuppiesSettings = (
            typeof props.settings.numberOfPuppies === 'number'
            && props.settings.numberOfPuppies >= 0
        )
        if(!validPuppiesSettings) {
            errorActions.push({
                type: 'SHOW_NUMBER_OF_PUPPIES_ERROR'
            })
        }
    }

    if(props.settings.isPregnant) {
        const validGestationWeeksSetting = (
            typeof props.settings.weeksInGestation === 'number'
            && props.settings.weeksInGestation >= 0
        )
        if(!validGestationWeeksSetting) {
            errorActions.push({
                type: 'SHOW_GESTATION_WEEKS_ERROR'
            })
        }
    }


    if(errorActions.length) {
        if(props.collapsed.generalSettings) {
            errorActions.push({
                type: 'EXPAND_GENERAL_SETTINGS'
            })
        }
    }

    return errorActions
}

const copy = item => {
    return !item ? item
            : Array.isArray(item) ? deepArrayCopy(item)
            : Object.prototype.toString.call(item).includes('Date') ? new Date(item.getTime())
            : typeof item === 'object' ? deepCopy(item)
            : item
}

const deepCopy = obj => {
    const objKeys = Object.keys(obj)
    const newObj = {}

    let i = objKeys.length
    let key, item
    while(i--) {
        key = objKeys[i]
        item = obj[key]
        newObj[key] = copy(item)
    }
    return newObj
}

const deepArrayCopy = arr => {
    let i = arr.length
    let item
    const newArr = []
    while(i--) {
        item = arr[i]
        newArr[i] = copy(item)
    }
    return newArr
}

const getLang = () => {
  if (navigator.languages !== undefined) 
    return navigator.languages[0]; 
  return navigator.language;
}

const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export {
    createReducer,
    shuffle,
    stringToColour, 
    delay,
    lbsToKgs,
    kgsToLbs,
    calculateMER,
    printResults,
    sigFigs,
    getRequirementSet,
    calculateMixNutrients100Grams,
    calculateMixNutrients,
    defaultNutrientSettings,
    validateDogSettings,
    copy,
    getLang,
    debounce
}