import React from 'react'

/*
signup steps: 
1. give email
2. give code sent to email
3. give salted password hash
*/


const Registration = ({props, dispatch, setView}) => {

	return (
		<div className="Registration">
			<h2>Register</h2>
			
		</div>
	)
}

export default Registration