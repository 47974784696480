import React, { useState } from 'react'

const Select = ({ options, onChange, value, updateHeight, disabledSelect }) => {
	const [height, setHeight] = React.useState(0)
	const contentGutsRef = React.useRef()

	const contentHeight = contentGutsRef?.current?.clientHeight
	const [open, setOpen] = useState(false)

	React.useEffect(() => {
		setHeight(contentGutsRef?.current?.clientHeight || 0)
	}, [contentHeight, updateHeight, open])

	const [selected, setSelected] = useState(value)
	React.useEffect(() => {
		setSelected(value)
	}, [value])
	const handleWindowClick = e => {
		setOpen(false)
		window.removeEventListener('click', handleWindowClick)
	}

	const selectedOption = options.find(opt => opt.value === selected)
	return <span className="Select flex">
		<span
			className={`title${options.length ? ' interactive' : ''}`}
			title={options.length ? undefined : 'No options available'}
			onClick={e => {
				e.stopPropagation()

				if(open) {
					setOpen(false)
					window.removeEventListener('click', handleWindowClick)
				} else if(options.length) {
					setOpen(true)
					setTimeout(() => window.addEventListener('click', handleWindowClick), 0)
				}
			}}
		>
			{
				selectedOption
				? selectedOption.title
				: <span className="placeholder">Select option</span>
			}
			<span className="collapse_caret">^</span>
		</span>
		<div 
			className="selectDrodown"
			style={{
				height: `${open ? height : 0}px`,
				borderWidth: `${open ? 1 : 0}px`
			}}
		>
			<div 
				className="selectDropdownContent" 
				ref={contentGutsRef}
				
			>{options.map(
				(opt, i) => <div 
					key={`select_optoin_${opt.value}`}
					className="option flex interactive"
					onClick={e => {
						e.preventDefault()
						e.stopPropagation()
						if(typeof onChange === 'function') {
							onChange(e, opt.value)
						} else if(typeof opt.onClick === 'function') {
							opt.onClick(e, opt.value)
						}
						if(disabledSelect) {
							return
						}
						setSelected(opt.value)
						setOpen(false)
					}}
				>
					{
						disabledSelect
						? null
						: <span 
							title="selected" 
							className={opt.value === selected ? '' : 'hidden'}
						>&#8594;&nbsp;</span>
					}
					{opt.content}
				</div>
			)}</div>
		</div>
	</span>
}

export default Select