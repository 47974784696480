import apple_raw from "./apple_raw.js"
import balance_it from "./balance_it.js"
import banana_raw from "./banana_raw.js"
import basil_fresh from "./basil_fresh.js"
import beans_cooked_pinto from "./beans_cooked_pinto.js"
import beans_without_salt_boiled_cooked_mature_seeds_red_kidney from "./beans_without_salt_boiled_cooked_mature_seeds_red_kidney.js"
import beef_ground_cooked from "./beef_ground_cooked.js"
import beef_raw_kidneys from "./beef_raw_kidneys.js"
import beef_raw_liver from "./beef_raw_liver.js"
import beef_tripe_cooked from "./beef_tripe_cooked.js"
import beef_tripe_raw from "./beef_tripe_raw.js"
import beets_drained_boiled_cooked from "./beets_drained_boiled_cooked.js"
import blackberries_raw from "./blackberries_raw.js"
import blueberries_raw from "./blueberries_raw.js"
import bone_meal_steamed from "./bone_meal_steamed.js"
import brewers_yeast_powder_by_bulk_supplements_llc_ from "./brewers_yeast_powder_by_bulk_supplements_llc_.js"
import broccoli_cooked from "./broccoli_cooked.js"
import broccoli_raw from "./broccoli_raw.js"
import cabbage_raw_green from "./cabbage_raw_green.js"
import cabbage_raw_red from "./cabbage_raw_red.js"
import cantaloupe_raw from "./cantaloupe_raw.js"
import carrots_cooked from "./carrots_cooked.js"
import carrots_raw from "./carrots_raw.js"
import cauliflower_cooked from "./cauliflower_cooked.js"
import cauliflower_raw from "./cauliflower_raw.js"
import chicken_cooked_liver from "./chicken_cooked_liver.js"
import chicken_raw_ground from "./chicken_raw_ground.js"
import chicken_roasted_cooked_meat from "./chicken_roasted_cooked_meat.js"
import cooked_oats from "./cooked_oats.js"
import corn_grain_yellow from "./corn_grain_yellow.js"
import corn_oil from "./corn_oil.js"
import corn_raw_yellow_sweet from "./corn_raw_yellow_sweet.js"
import corn_yellow_sweet_corn from "./corn_yellow_sweet_corn.js"
import cottage_cheese from "./cottage_cheese.js"
import dandelion_greens_raw from "./dandelion_greens_raw.js"
import dill_weed_fresh from "./dill_weed_fresh.js"
import egg_hardboiled_cooked_whole from "./egg_hardboiled_cooked_whole.js"
import fish_meal from "./fish_meal.js"
import fish_oil_salmon from "./fish_oil_salmon.js"
import flax_seeds from "./flax_seeds.js"
import green_beans_cooked from "./green_beans_cooked.js"
import green_beans_raw from "./green_beans_raw.js"
import green_peas_raw from "./green_peas_raw.js"
import hard_red_spring_wheat_berries_by_bobs_red_mill_natural_foods_inc from "./hard_red_spring_wheat_berries_by_bobs_red_mill_natural_foods_inc.js"
import lamb_cooked_ground from "./lamb_cooked_ground.js"
import lamb_raw_kidneys from "./lamb_raw_kidneys.js"
import lentils_raw from "./lentils_raw.js"
import lima_beans_raw_mature_seeds_large from "./lima_beans_raw_mature_seeds_large.js"
import millet_raw from "./millet_raw.js"
import molasses from "./molasses.js"
import mustard_greens_raw from "./mustard_greens_raw.js"
import olive_oil from "./olive_oil.js"
import organic_extra_virgin_coconut_oil_by_walmart_stores_inc from "./organic_extra_virgin_coconut_oil_by_walmart_stores_inc.js"
import parsley_fresh from "./parsley_fresh.js"
import peanut_butter_without_salt_smooth_style from "./peanut_butter_without_salt_smooth_style.js"
import peas_raw_green from "./peas_raw_green.js"
import pork_cooked_ground from "./pork_cooked_ground.js"
import pumpkin_without_salt_drained_boiled_cooked from "./pumpkin_without_salt_drained_boiled_cooked.js"
import quinoa_cooked from "./quinoa_cooked.js"
import raw_elk from "./raw_elk.js"
import rice_cooked_brown from "./rice_cooked_brown.js"
import rice_cooked_white from "./rice_cooked_white.js"
import roasted_cooked_elk from "./roasted_cooked_elk.js"
import rosemary_fresh from "./rosemary_fresh.js"
import salt_table from "./salt_table.js"
import seaweed_raw_kelp from "./seaweed_raw_kelp.js"
import sesame_seeds from "./sesame_seeds.js"
import soft_white_wheat_berries_by_bobs_red_mill_natural_foods_inc from "./soft_white_wheat_berries_by_bobs_red_mill_natural_foods_inc.js"
import squash_baked_butternut from "./squash_baked_butternut.js"
import squash_raw_includes_skin_zucchini_summer from "./squash_raw_includes_skin_zucchini_summer.js"
import sunflower_oil from "./sunflower_oil.js"
import sunflower_seeds_unsalted_plain from "./sunflower_seeds_unsalted_plain.js"
import sweet_potato_boiled from "./sweet_potato_boiled.js"
import turkey_cooked_ground from "./turkey_cooked_ground.js"
import wheat_uncooked_kamut_khorasan from "./wheat_uncooked_kamut_khorasan.js"
import whole_grain_oat_groats_by_bobs_red_mill_natural_foods_inc from "./whole_grain_oat_groats_by_bobs_red_mill_natural_foods_inc.js"
import yogurt_whole_milk from "./yogurt_whole_milk.js"

const ingredients = {apple_raw,balance_it,banana_raw,basil_fresh,beans_cooked_pinto,beans_without_salt_boiled_cooked_mature_seeds_red_kidney,beef_ground_cooked,beef_raw_kidneys,beef_raw_liver,beef_tripe_cooked,beef_tripe_raw,beets_drained_boiled_cooked,blackberries_raw,blueberries_raw,bone_meal_steamed,brewers_yeast_powder_by_bulk_supplements_llc_,broccoli_cooked,broccoli_raw,cabbage_raw_green,cabbage_raw_red,cantaloupe_raw,carrots_cooked,carrots_raw,cauliflower_cooked,cauliflower_raw,chicken_cooked_liver,chicken_raw_ground,chicken_roasted_cooked_meat,cooked_oats,corn_grain_yellow,corn_oil,corn_raw_yellow_sweet,corn_yellow_sweet_corn,cottage_cheese,dandelion_greens_raw,dill_weed_fresh,egg_hardboiled_cooked_whole,fish_meal,fish_oil_salmon,flax_seeds,green_beans_cooked,green_beans_raw,green_peas_raw,hard_red_spring_wheat_berries_by_bobs_red_mill_natural_foods_inc,lamb_cooked_ground,lamb_raw_kidneys,lentils_raw,lima_beans_raw_mature_seeds_large,millet_raw,molasses,mustard_greens_raw,olive_oil,organic_extra_virgin_coconut_oil_by_walmart_stores_inc,parsley_fresh,peanut_butter_without_salt_smooth_style,peas_raw_green,pork_cooked_ground,pumpkin_without_salt_drained_boiled_cooked,quinoa_cooked,raw_elk,rice_cooked_brown,rice_cooked_white,roasted_cooked_elk,rosemary_fresh,salt_table,seaweed_raw_kelp,sesame_seeds,soft_white_wheat_berries_by_bobs_red_mill_natural_foods_inc,squash_baked_butternut,squash_raw_includes_skin_zucchini_summer,sunflower_oil,sunflower_seeds_unsalted_plain,sweet_potato_boiled,turkey_cooked_ground,wheat_uncooked_kamut_khorasan,whole_grain_oat_groats_by_bobs_red_mill_natural_foods_inc,yogurt_whole_milk}

export default ingredients