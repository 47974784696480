const GRANULARITY = 10

const NUTRIENT_UNITS = {
    "Calories": "KCAL",
    "Fat": "G",
    "Protein": "G",
    "Carbohydrate": "G",
    "Alcohol, ethyl": "G",
    "Water": "G", 
    "Caffeine": "MG", 
    "Theobromine": "MG", 
    "Sugars": "G", 
    "Fiber": "G",
    "Calcium, Ca": "MG",
    "Iron, Fe": "MG",
    "Magnesium, Mg": "MG",
    "Phosphorus, P": "MG",
    "Potassium, K": "MG",
    "Sodium, Na": "MG",
    "Zinc, Zn": "MG",
    "Copper, Cu": "MG",
    "Selenium, Se": "UG",
    "Retinol": "UG", 
    "Vitamin A, RAE": "UG",
    "Carotene, beta": "UG", 
    "Carotene, alpha": "UG", 
    "Vitamin E": "MG",
    "Vitamin D": "UG",
    "Cryptoxanthin, beta": "UG",
    "Lycopene": "UG", 
    "Lutein + zeaxanthin": "UG", 
    "Vitamin C": "MG",
    "Thiamin": "MG",
    "Riboflavin": "MG",
    "Niacin": "MG",
    "Vitamin B6": "MG",
    "Folate": "UG", 
    "Vitamin B12": "UG",
    "Choline": "MG", 
    "Vitamin K": "UG",
    "Folic acid": "UG",
    "Folate, food": "UG", 
    "Folate, DFE": "UG",
    "Vitamin E, added": "MG", 
    "Vitamin B12, added": "UG",
    "Cholesterol": "MG",
    "Saturated fatty acids": "G",
    "Butanoic acid": "G", 
    "Hexanoic acid": "G", 
    "Octanoic acid": "G", 
    "Decanoic acid": "G", 
    "Dodecanoic acid": "G", 
    "Tetradecanoic acid": "G", 
    "Hexadecanoic acid": "G", 
    "Octadecanoic acid": "G", 
    "Octadecenoic acid": "G", 
    "Octadecadienoic acid": "G", 
    "Octadecatrienoic acid": "G", 
    "Eicosatetraenoic acid": "G", 
    "Docosahexaenoic n-3 acid (DHA)": "G", 
    "Hexadecenoic acid": "G", 
    "Octadecatetraenoic acid": "G", 
    "Eicosenoic acid": "G", 
    "Eicosapentaenoic n-3 acid (EPA)": "G", 
    "Docosenoic acid": "G", 
    "Docosapentaenoic n-3 acid (DPA)": "G", 
    "Monounsaturated fatty acids": "G", 
    "Polyunsaturated fatty acids": "G", 
    "Tocopherol, alpha": "MG", 
    "Net carbs": "G", 
    "Tyrosine": "G",
    "Alanine": "G",
    "Glutamic acid": "G",
    "Glycine": "G",
    "Proline": "G",
    "Cystine": "G",
    "Aspartic acid": "G",
    "Serine": "G",
    "Vitamin A RAE": "UG",
    "Fatty acids total trans": "G",
    "Glucose": "G",
    "Tocopherol gamma": "G",
    "Folate food": "UG",
    "Galactose": "G",
    "Carotene alpha": "UG",
    "Ash": "G",
    "Tocopherol alpha": "MG",
    "Hydroxyproline": "G",
    "Pentadecanoic acid": "G",
    "Ciscis-eicosadienoic n-6 acid": "G",
    "γ-linolenic acid n-6 (GLA)": "G",
    "Heptadecenoic acid": "G",
    "α-linolenic acid n-3 (ALA)": "G",
    "Vitamin D3": "UG"
}

export {
	GRANULARITY,
	NUTRIENT_UNITS
}