import { calculateMER, copy } from '../../utils.js'

import localForage from "localforage"

const locallySavedIngredientsSet = new Set(
	(
		localStorage.getItem('ingredients') 
		|| ''
	).split(',') 
	|| []
)

const actions = {
	SET_SPECIES: (state, action) => {
		state.settings.species = action.species
		return state
	},
	SET_NUTRIENT_WEIGHT: (state, action) => {
		if(!state.nutrientSettings[action.nutrient]) {
			state.nutrientSettings[action.nutrient] = {}
		}
		state.nutrientSettings[action.nutrient].weight = action.weight
		return state
	},
	SET_ANIMAL_WEIGHT: (state, action) => {
		state.settings.currentWeight = action.weight
		return state
	},
	SET_ANIMAL_ADULT_WEIGHT: (state,action) => {
		state.settings.adultWeight = action.weight
		return state
	},
	SET_DOG_NAME: (state, action) => {
		localStorage.setItem('dogName', action.name)
		state.settings.name = action.name
		return state
	},
	SET_DOG_SETTINGS_SETS: (state, action) => {
		state.dogs = action.dogs
		return state
	},
	ADD_DOG_SETTINGS_SET: (state, action) => {
		state.dogs.push(copy(state.settings))
		localForage.setItem('dogSettings', state.dogs)
		return state
	},
	EDIT_DOG_SETTINGS_SET: (state, action) => {
		const index = state.dogs.findIndex(dog => dog.name === state.selectedDog)
		state.dogs[index] = copy(state.settings)
		localForage.setItem('dogSettings', state.dogs)
		return state
	},
	DELETE_DOG_SETTINGS_SET: (state, action) => {
		if(state.selectedDog === action.name) {
			state.selectedDog = undefined
		}
		state.dogs = state.dogs.filter(dog => dog.name !== action.name)
		localForage.setItem('dogSettings', state.dogs)

		return state
	},
	SET_SETTINGS: (state, action) => {
		if(action.settings) state.settings = action.settings
		if(action.ingredientsSettings) state.ingredientsSettings = action.ingredientsSettings
		if(action.nutrientSettings) state.nutrientSettings = action.nutrientSettings
		return state
	},
	SELECT_DOG_SETTINGS: (state, action) => {
		localStorage.setItem('selectedDog', action.name)
		const dogSettings = state.dogs.find(dog => dog.name === action.name)
		if(dogSettings) {
			state.selectedDog = action.name
			state.settings = copy(dogSettings)
		} else {
			state.selectedDog = undefined
			state.settings = {
				name: '',

				years: undefined,
				inEarlyGrowth: undefined,
				currentWeight: undefined,
				adultWeight: undefined,
				isLactating: false,
				isPregnant: false,
				fatness: 'ideal',

				weeksInGestation: undefined,
				weeksInLactation: undefined,
				numberOfPuppies: undefined,

				hoursLowImpactActivity: undefined,
				hoursHighImpactActivity: undefined
			}
		}

		return state
	},
	SET_DOG_NAME_ERROR: (state, action) => {
		state.errors.name = action.error
		return state
	},
	SET_IGNORE_NUTRIENTS_EXCESS: (state, action) => {
		action.nutrients.forEach(nutrient => {
			if(!state.nutrientSettings[nutrient]) {
				state.nutrientSettings[nutrient] = {}
			}
			state.nutrientSettings[nutrient].ignoreExcess = action.ignoreExcess
		})
		return state
	},
	SET_IGNORE_NUTRIENT_EXCESS: (state, action) => {
		if(!state.nutrientSettings[action.nutrient]) {
			state.nutrientSettings[action.nutrient] = {}
		}
		state.nutrientSettings[action.nutrient].ignoreExcess = action.ignoreExcess
		return state
	},
	SELECT_INGREDIENTS: (state, action) => {
		action.ingredients.forEach(ingredient => {
			const { min=0, max=100 } = state.ingredientsSettings[ingredient] || {}
			state.ingredientsSettings[ingredient] = { selected: true, min, max }
			localStorage.setItem(`ingredient_${ingredient}`, [true, min, max].join(','))
			locallySavedIngredientsSet.add(ingredient)
		})
		localStorage.setItem('ingredients', Array.from(locallySavedIngredientsSet).join(','))
		return state
	},
	DESELECT_INGREDIENTS: (state, action) => {
		action.ingredients.forEach(ingredient => {
			state.ingredientsSettings[ingredient].selected = false
			state.ingredientsSettings[ingredient].min = 0
			state.ingredientsSettings[ingredient].max = 100
			localStorage.setItem(`ingredient_${ingredient}`, [false, 0, 100].join(','))
		})
		return state
	},
	SELECT_INGREDIENT: (state, action) => {
		const { min=0, max=100 } = state.ingredientsSettings[action.ingredient] || {}
		state.ingredientsSettings[action.ingredient] = { selected: true, min, max }
		
		localStorage.setItem(`ingredient_${action.ingredient}`, [true, min, max].join(','))
		locallySavedIngredientsSet.add(action.ingredient)
		localStorage.setItem('ingredients', Array.from(locallySavedIngredientsSet).join(','))

		return state
	},
	DESELECT_INGREDIENT: (state, action) => {
		state.ingredientsSettings[action.ingredient].selected = false
		state.ingredientsSettings[action.ingredient].min = 0
		state.ingredientsSettings[action.ingredient].max = 100
		localStorage.setItem(`ingredient_${action.ingredient}`, [false, 0, 100].join(','))
		return state
	},
	CLEAR_SELECTED_INGREDIENTS: (state, action) => {
		Object.keys(state.ingredientsSettings).forEach(ingredient => {
			state.ingredientsSettings[ingredient].selected = false
			state.ingredientsSettings[ingredient].min = 0
			state.ingredientsSettings[ingredient].max = 100
			localStorage.setItem(`ingredient_${ingredient}`, [false, 0, 100].join(','))
		})
		return state
	},
	SET_PROPORTIONS: (state, action) => {
		state.proportions = action.proportions
		state.fitness = action.fitness
		return state
	},
	PENALIZE_NUTRIENT_EXCESS: (state, action) => {
		state.settings.penalizeExcess = true
		return state
	},
	IGNORE_NUTRIENT_EXCESS: (state, action) => {
		state.settings.penalizeExcess = false
		return state
	},
	SET_INGREDIENT_MIN: (state, action) => {
		state.ingredientsSettings[action.ingredient].min = action.minimum
		const { selected, min, max } = state.ingredientsSettings[action.ingredient]
		localStorage.setItem(`ingredient_${action.ingredient}`, [selected, min, max].join(','))
		return state
	},
	SET_INGREDIENT_MAX: (state, action) => {
		state.ingredientsSettings[action.ingredient].max = action.maximum
		const { selected, min, max } = state.ingredientsSettings[action.ingredient]
		localStorage.setItem(`ingredient_${action.ingredient}`, [selected, min, max].join(','))
		return state
	},
	EXPAND_GENERAL_SETTINGS: (state, action) => {
		state.collapsed.generalSettings = false
		return state
	},
	COLLAPSE_GENERAL_SETTINGS: (state, action) => {
		state.collapsed.generalSettings = true
		return state
	},
	EXPAND_NUTRIENT_SETTINGS: (state, action) => {
		state.collapsed.nutrientSettings = false
		return state
	},
	COLLAPSE_NUTRIENT_SETTINGS: (state, action) => {
		state.collapsed.nutrientSettings = true
		return state
	},
	EXPAND_INGREDIENT_SELECTOR: (state, action) => {
		state.collapsed.ingredientsSelector = false
		return state
	},
	COLLAPSE_INGREDIENT_SELECTOR: (state, action) => {
		state.collapsed.ingredientsSelector = true
		return state
	},
	EXPAND_BALANCE_GRAPH: (state, action) => {
		state.collapsed.balanceGraph = false
		return state
	},
	COLLAPSE_BALANCE_GRAPH: (state, action) => {
		state.collapsed.balanceGraph = true
		return state
	},
	EXPAND_RESULTS: (state, action) => {
		state.collapsed.results = false
		return state
	},
	COLLAPSE_RESULTS: (state, action) => {
		state.collapsed.results = true
		return state
	},
	START_RUNNING: (state, action) => {
		state.running = true
		return state
	},
	DONE_RUNNING: (state, action) => {
		state.running = false
		state.loaded = true
		return state
	},
	UPDATE_MER: (state, action) => {
		const isValidAge = typeof state.settings.years === 'number' && state.settings.years >= 0
		const isValidWeight = typeof state.settings.currentWeight === 'number' && state.settings.currentWeight
		const isValidAdultWeight = (
			state.settings.years === 0 
			? (
				typeof state.settings.adultWeight === 'number' 
				&& state.settings.adultWeight > state.settings.currentWeight
			)
			: true
		)
		const isValidGestationSettings = (
			state.settings.isLactating 
			? (
				typeof state.settings.weeksInLactation === 'number'
				&& state.settings.weeksInLactation >= 0
				&& typeof state.settings.numberOfPuppies === 'number'
				&& state.settings.numberOfPuppies >= 0
			)
			: state.settings.isPregnant
			? (
				typeof state.settings.weeksInGestation === 'number'
				&& state.settings.weeksInGestation >= 0
			)
			: true
		)
		const isValidSettings = (
			isValidAge 
			&& isValidWeight 
			&& isValidAdultWeight 
			&& isValidGestationSettings
		)
		if(isValidSettings) {
			state.mer = calculateMER(state.settings)
		} else {
			state.mer = null
		}
		return state
	},
	UPDATE_YEARS: (state, action) => {
		state.settings.years = action.years
		return state
	},
	CHECK_EARLY_GROWTH: (state, action) => {
		state.settings.inEarlyGrowth = true
		return state
	},
	UNCHECK_EARLY_GROWTH: (state, action) => {
		state.settings.inEarlyGrowth = false
		return state
	},
	SET_FATNESS: (state, action) => {
		state.settings.fatness = action.fatness
		return state
	},
	CHECK_IS_LACTATING: (state, action) => {
		state.settings.isLactating = true
		return state
	},
	UNCHECK_IS_LACTATING: (state, action) => {
		state.settings.isLactating = false
		return state
	},
	CHECK_IS_PREGNANT: (state, action) => {
		state.settings.isPregnant = true
		return state
	},
	UNCHECK_IS_PREGNANT: (state, action) => {
		state.settings.isPregnant = false
		return state
	},
	UPDATE_WEEKS_GESTATING: (state, action) => {
		state.settings.weeksInGestation = action.weeks
		return state
	},
	UPDATE_WEEKS_LACTATING: (state, action) => {
		state.settings.weeksInLactation = action.weeks
		return state
	},
	UPDATE_NUMBER_OF_PUPPIES: (state, action) => {
		state.settings.numberOfPuppies = action.count
		return state
	},
	UPDATE_LOW_IMPACT_HOURS: (state, action) => {
		state.settings.hoursLowImpactActivity = action.hours
		return state
	},
	UPDATE_HIGH_IMPACT_HOURS: (state, action) => {
		state.settings.hoursHighImpactActivity = action.hours
		return state
	},
	SET_MIX_SETTINGS: (state, action) => {
		state.mixSettings = action.settings
		state.mixIngredients = action.ingredientsSettings
		state.mixNutrientSettings = action.nutrientSettings
		return state
	},
	STEP_TO_ABOUT: (state, action) => {
		state.view = 'about'
		return state
	},
	STEP_TO_BALANCER: (state, action) => {
		state.view = 'balancer'
		return state
	},

	SHOW_AGE_ERROR: (state, action) => {
		state.errors.age = 'Enter a number'
		return state
	},
	CLEAR_AGE_ERROR: (state, action) => {
		state.errors.age = null
		return state
	},
	SHOW_WEIGHT_ERROR: (state, action) => {
		state.errors.weight = 'Enter a number'
		return state
	},
	CLEAR_WEIGHT_ERROR: (state, action) => {
		state.errors.weight = null
		return state
	},
	SHOW_ADULT_WEIGHT_ERROR: (state, action) => {
		state.errors.adultWeight = 'Enter a number'
		return state
	},
	CLEAR_ADULT_WEIGHT_ERROR: (state, action) => {
		state.errors.adultWeight = null
		return state
	},
	SHOW_LACTATION_WEEKS_ERROR: (state, action) => {
		state.errors.weeksInLactation = 'Enter a number'
		return state
	},
	CLEAR_LACTATION_WEEKS_ERROR: (state, action) => {
		state.errors.weeksInLactation = null
		return state
	},
	SHOW_NUMBER_OF_PUPPIES_ERROR: (state, action) => {
		state.errors.numberOfPuppies = 'Enter a number'
		return state
	},
	CLEAR_NUMBER_OF_PUPPIES_ERROR: (state, action) => {
		state.errors.numberOfPuppies = null
		return state
	},
	SHOW_GESTATION_WEEKS_ERROR: (state, action) => {
		state.errors.weeksInGestation = 'Enter a number'
		return state
	},
	CLEAR_GESTATION_WEEKS_ERROR: (state, action) => {
		state.errors.weeksInGestation = null
		return state
	},
	SET_INGREDIENT_SETS: (state, action) => {
		state.ingredientSets = action.sets
		return state
	},
	PUT_INGREDIENT_SET: (state, action) => {
		state.ingredientSets[action.setName] = action.ingredients
		localForage.setItem('ingredientSets', state.ingredientSets)
		return state
	},
	DELETE_INGREDIENT_SET: (state, action) => {
		const keys = Object.keys(state.ingredientSets).filter(
			setName => setName !== action.setName
		)
		const newIngredientSets = {}
		keys.forEach(key => {
			newIngredientSets[key] = state.ingredientSets[key]
		})
		state.ingredientSets = newIngredientSets

		localForage.setItem('ingredientSets', newIngredientSets)

		return state
	},
	ADD_RECIPE: (state, action) => {
		state.recipes.push(action.recipe)
		localForage.setItem('recipes', state.recipes)
		state.recipeSaved = state.recipes.length - 1
		return state
	},
	UPDATE_RECIPE: (state, action) => {
		state.recipes[action.recipe].title = action.title
		localForage.setItem('recipes', state.recipes)
		return state
	},
	DELETE_RECIPE: (state, action) => {
		state.recipes = state.recipes.filter((recipe, i) => i !== action.index)
		if(action.index === state.recipeSaved) {
			state.recipeSaved = -1
		} else if(state.recipeSaved >= 0 && state.recipeSaved > action.index) {
			state.recipeSaved = state.recipeSaved - 1
		}
		localForage.setItem('recipes', state.recipes)
		return state
	},
	NAVIGATE: (state, action) => {
		state.view = action.view
		return state
	},
	RECIPE_SAVED: (state, action) => {
		state.recipeSaved = action.index
		return state
	},
	RECIPE_UNSAVED: (state, action) => {
		state.recipeSaved = -1
		return state
	},
	SELECT_RECIPE: (state, action) => {
		state.selectedRecipe = action.index
		return state
	},
	SET_RECIPES: (state, action) => {
		state.recipes = action.recipes
		return state
	},
	SET_RECIPE_NAME: (state, action) => {
		state.recipeName = action.recipeName
		return state
	},
	SET_BALANCER_VIEW: (state, action) => {
		if(['settings', 'ingredients', 'balance', 'results'].includes(action.view)) {
			state.balancerView = action.view
		}
		return state
	}
}

export default actions