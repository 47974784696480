import React from 'react'

import Tooltip from '../../components/Tooltip/Tooltip.jsx'

import { lbsToKgs, sigFigs } from '../../utils.js'

const MERPreview = ({ mer, settings }) => {
	if(!mer) {
		return null
	}
	const currentWeightKgs = lbsToKgs(settings.currentWeight)
	const adjustedWeight = currentWeightKgs ** 0.75
	const dailyCalories =  mer * adjustedWeight
	const cals = sigFigs(dailyCalories, 2)

	return (
		<div className="MERPreview">
			<div>
				<span>Daily MER</span>
				<Tooltip tip="MER given in kcals per kg dog weight" shiftLeft={true}/>
				<span>: {sigFigs(mer, 2)}kcals/kg</span>
			</div>
			<div>
				<span>Daily calories: {cals ? `${cals}kcals` : ''}</span>
			</div>
		</div>
	)
}

export default MERPreview