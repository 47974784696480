import React from 'react'

const Tooltip = ({ tip, shiftLeft }) => {
	return (
		<span className="Tooltip">
			<span className="icon">&#63;</span>
			<div className={`tip${shiftLeft ? ' left' : ''}`}>{tip}</div>
		</span>
	)
}

export default Tooltip