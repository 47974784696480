import React, { useState, useRef } from 'react'

import Input from '../../components/Input/Input.jsx'

/*
Recovery steps: 
1. give email
2. give code sent to email
3. give new password hash
*/

const AccountRecoveryPassword = ({props, dispatch, setLoginView, setRecoveryView}) => {
	const [passwordError, setPasswordError] = useState('')
	const passwordRef = useRef()

	const [confirmPasswordError, setConfirmPasswordError] = useState('')
	const confirmPasswordRef = useRef()

	return (
		<div className="AccountRecoveryPassword">
			<form
				onSubmit={e => {
					e.preventDefault()

				}}
			>
				<Input 
					id="account_recovery_new_password"
					label="New Password"
					useRef={passwordRef}
					error={passwordError}
					setError={setPasswordError}
				/>
				<Input 
					id="account_recovery_confirm_password"
					label="Confirm Password"
					useRef={confirmPasswordRef}
					error={confirmPasswordError}
					setError={setConfirmPasswordError}
				/>
				<button type="submit">Submit</button>
			</form>
			<div className="interactive" onClick={e => setLoginView('login')}>Cancel</div>
		</div>
	)
}

const AccountRecoveryCode = ({props, dispatch, setLoginView, setRecoveryView}) => {
	const [error, setError] = useState('')
	const codeInput = useRef()
	return (
		<div className="AccountRecoveryCode">
			<form
				onSubmit={e => {
					e.preventDefault()
					const code = codeInput.current.value
					if(!code) {
						setError('Code required')
						return
					}
				}}
			>
				<h2>Email Code</h2>
				<Input 
					id="account_recovery_code"
					label="Code"
					useRef={codeInput}
					error={error}
					setError={setError}
				/>
				<button type="submit">Submit</button>
			</form>
			<div onClick={e => setRecoveryView('email')}>Back</div>
		</div>
	)
}

const AccountRecoveryEmail = ({props, dispatch, setLoginView, setRecoveryView}) => {
	const [error, setError] = useState('')
	const emailInput = useRef()
	return (
		<div className="AccountRecoveryEmail">
			<form onSubmit={e => {
				e.preventDefault()
				const email = emailInput.current.value
				if(!email) {
					setError('Email required')
					return 
				}
				// TODO: actually ping endpoint to send email....
				// THEN step to code form
			}}>
				<h2>Account Email</h2>
				<Input 
					id="recover_account_email"
					label="Account Recovery Email"
					useRef={emailInput}
					error={error}
					setError={setError}
				/>
				<button type="submit">Submit</button>
			</form>
			<div className="interactive" onClick={e => setLoginView('login')}>Back to login</div>
		</div>
	)
}

const AccountRecovery = ({props, dispatch, setView}) => {
	const [view, setRecoveryView] = useState('email')
	return (
		<div className="AccountRecovery">{
			view === 'email' ? <AccountRecoveryEmail props={props} dispatch={dispatch} setLoginView={setView} setRecoveryView={setRecoveryView}/>
			: view === 'code' ? <AccountRecoveryCode props={props} dispatch={dispatch} setLoginView={setView} setRecoveryView={setRecoveryView}/>
			: view === 'password' ? <AccountRecoveryPassword props={props} dispatch={dispatch} setLoginView={setView} setRecoveryView={setRecoveryView}/>
			: null
		}</div>
	)
}

export default AccountRecovery